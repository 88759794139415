import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom'
import {
  CHeader,
  CHeaderNav,
  CNavLink,
  CNavItem,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CRow,
  CCol,
  CFormInput,
  CModalFooter,
  CButton,
  CSpinner,
  CAlert,
  CPopover,
  CFormSwitch,
} from '@coreui/react'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import { FaBars } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'

import { HiHome, HiMenu } from 'react-icons/hi'
import {
  BiLeftArrow,
  BiRightArrow,
  BiFullscreen,
  BiExitFullscreen,
  BiSolidHelpCircle,
  BiZoomIn,
  BiZoomOut,
  BiMenuAltRight,
  BiTestTube,
  BiInfoCircle,
  BiTable,
  BiX,
} from 'react-icons/bi'
import { FcCalculator } from 'react-icons/fc'
import { FiSettings } from 'react-icons/fi'
import noteIcon from '../../assets/images/post-it.png'
// import markIcon from '../../assets/images/mark-flag.png'
import markIcon from '../../assets/images/mark-icon.svg'
// import { ReactCalculator } from 'simple-react-calculator'
import ReactStickies from 'react-stickies'
import { API_URL } from 'src/store'
import { FaUndoAlt } from 'react-icons/fa'
import Calculator from './Calculator'
// isTimer={isTimer}
//         setIsTimer={setIsTimer}
const QuizHeader = ({
  currentQuestion,
  setCurrentQuestion,
  showQues,
  totalQues,
  filteredArray,
  fontSize,
  setFontSize,
  isTimer,
  setIsTimer,
  markedQuestions,
  setMarkedQuestions,
  undoHighlight,
  highlightStack,
  toggleSidebar,
  examId,
}) => {
  const headerRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const [fullscreen, setFullscreen] = useState(false)
  const [showCalculator, setShowCalculator] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const [notes, setNotes] = useState([])
  const [selectedOption, setSelectedOption] = useState('')
  const [marked, setMarked] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const [commentError, setCommentError] = useState('')
  const [commentModal, setCommentModal] = useState(false)
  const [questionId, setQuestionId] = useState('')
  const [quizEnd, setQuizEnd] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [token, setToken] = useState(localStorage.getItem('token') || '')
  const [userID, setUSerID] = useState(localStorage.getItem('userId') || '')
  const [timer, setTimer] = useState(true)
  const [isUndoClicked, setIsUndoClicked] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(1)

  const toggleMeduin = () => {
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    const getToken = localStorage.getItem('token')
    if (getToken) {
      setToken(getToken)
      const getUserId = localStorage.getItem('userId')
      setUSerID(getUserId)
    } else {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0)
    })
  }, [])

  // Function to toggle the calculator
  const toggleCalculator = () => {
    setShowCalculator((prevState) => !prevState) // Toggle the state
  }

  const onChange = (notes) => {
    setNotes(notes)
  }

  const onSave = () => {
    // Make sure to delete the editorState before saving to backend
    // console.log('save function called', notes)
    const notes = notes
    notes.map((note) => {
      delete note.editorState
    })
    // Make service call to save notes
    // Code goes here...
  }

  const toggleFullscreen = () => {
    setFullscreen((prevCheck) => !prevCheck)
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const handlePrevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1)
      setSelectedOption('')
    }
    setMarked(false)
    setQuestionId('')
    setCommentValue('')
  }
  const handleNextQuestion = () => {
    if (currentQuestion + 1 < totalQues) {
      setCurrentQuestion(currentQuestion + 1)
      setSelectedOption('')
      setMarkedQuestions(markedQuestions.filter((id) => id !== questionId)) // Remove current question from marked questions
      setMarked(false)
      setQuestionId('')
      setCommentValue('')
    } else {
      setQuizEnd(true)
    }
  }

  const toggleMarked = (e) => {
    const questionNumber = currentQuestion
    const isMarked = markedQuestions.includes(questionNumber)

    if (!isMarked) {
      setMarkedQuestions([...markedQuestions, questionNumber])
    } else {
      setMarkedQuestions(markedQuestions.filter((num) => num !== questionNumber))
    }
  }

  const handleAddComment = () => {
    setCommentModal(true)
    setError(false)
    setErrorMsg('')
  }

  const addComment = () => {
    // console.log('comment', commentValue)
    setIsLoading(true)

    // Get the ID of the current question
    let currentQuestionId = ''
    if (location.pathname.includes('review-quiz')) {
      currentQuestionId = filteredArray[currentQuestion].questionId?._id
    } else {
      currentQuestionId = filteredArray[currentQuestion]._id
    }

    if (!currentQuestionId) {
      setError(true)
      setErrorMsg('Cannot find ID of the current question.')
      setIsLoading(false)
      return
    }

    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    myHeaders.append('Content-Type', 'application/json')

    const raw = JSON.stringify({
      commentText: commentValue,
    })
    let url
    if (location.pathname.includes('full-length-exam')) {
      url = `${API_URL}comment-in-test/${currentQuestionId}`
    } else {
      url = `${API_URL}add-comment/${currentQuestionId}`
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setCommentModal(false)
          setIsLoading(false)
          setSuccess(true)
          setSuccessMsg('Comment sent successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setError(true)
          setErrorMsg(result.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('Error adding comment:', error)
        setIsLoading(false)
      })
  }

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <CHeader position="sticky" className="p-0 py-1 quiz-header px-4">
        <div className="flex justify-center items-center py-2">
          {/* {showQues && ( */}
          <Link to="/">
            <HiHome className="quiz-icons text-xl sm:text-2xl transition-all hover:text-[#6261CC] cursor-pointer mr-2" />
          </Link>
          {/* )} */}
          {showQues && (
            <div className="flex justify-start items-center">
              <div className="flex justify-start items-center">
                <h1 className="mr-5 sm:text-xl">
                  Question {currentQuestion + 1} of {totalQues}
                </h1>
                <div className="flex flex-col justify-center">
                  <div className="flex justify-center items-center">
                    <CFormCheck
                      inline
                      id={
                        filteredArray[currentQuestion] && filteredArray[currentQuestion].questionId
                          ? filteredArray[currentQuestion].questionId._id
                          : filteredArray[currentQuestion] && filteredArray[currentQuestion]._id
                            ? filteredArray[currentQuestion]._id
                            : ''
                      }
                      checked={markedQuestions.includes(currentQuestion)}
                      label=""
                      onChange={(e) => toggleMarked(e)}
                    />
                    <img
                      src={markIcon}
                      alt="mark icon"
                      className="cursor-pointer h-4 w-4 sm:w-5 sm:h-5"
                    />
                  </div>
                  <span className="text-xs">Mark</span>
                </div>
              </div>
              <CButton
                color="primary"
                className="ml-3 hidden sm:flex text-[10px] sm:text-lg "
                onClick={handleAddComment}
              >
                Add Comment
              </CButton>
              <CButton
                color="primary"
                className="ml-3 flex sm:hidden text-[10px] sm:text-lg "
                onClick={handleAddComment}
              >
                Comment
              </CButton>
              {location.pathname.includes('review-quiz') ? (
                ''
              ) : (
                <div className="p-1 ml-1">
                  {highlightStack.length > 0 ? (
                    <button
                      onClick={undoHighlight}
                      title="Undo Highlight"
                      className={`p-2 rounded-md hover:bg-gray-700 focus:outline-none transform transition-transform duration-200 hover:scale-90`}
                    >
                      <FaUndoAlt className="text-2xl" />
                    </button>
                  ) : null}
                </div>
              )}
              {/* {markedQuestions.length > 0 && (
                <CDropdown className="ml-3">
                  <CDropdownToggle color="secondary">Marked Questions</CDropdownToggle>
                  <CDropdownMenu>
                    {markedQuestions.map((questionNumber) => (
                      <CDropdownItem
                        key={questionNumber}
                        onClick={() => handleQuestionNavigation(questionNumber)}
                      >
                        Question No : {questionNumber}
                      </CDropdownItem>
                    ))}
                  </CDropdownMenu>
                </CDropdown>
              )} */}
            </div>
          )}
        </div>
        <div className="flex sm:hidden" onClick={toggleMeduin}>
          {isOpen ? <IoClose /> : <FaBars />}
        </div>
        {isOpen && (
          <div
            className={`meduin absolute bg-[#212631] pr-2 pl-4 rounded-2xl py-6 top-12 right-1 transition-all duration-300 ease-in-out`}
          >
            <CHeaderNav className="flex flex-col gap-3">
              <CPopover content="Please email ajmonics@gmail.com" placement="bottom">
                <CButton color="link p-0">
                  <BiSolidHelpCircle className="quiz-icons mr-2 cursor-pointer" />
                </CButton>
              </CPopover>
              <div onClick={() => setShowNotes((prevCheck) => !prevCheck)}>
                <img src={noteIcon} alt="notes icon" className="mr-2 cursor-pointer" />
              </div>
              <FcCalculator className="quiz-icons mr-2 cursor-pointer" onClick={toggleCalculator} />
              <BiZoomIn
                className="quiz-icons mr-2 cursor-pointer"
                onClick={() => setFontSize(fontSize + 1)}
              />
              {fontSize > 16 && showQues ? (
                <BiZoomOut
                  className="quiz-icons mr-2 cursor-pointer"
                  onClick={() => setFontSize(fontSize - 1)}
                />
              ) : (
                ''
              )}
              {/* {location.pathname == '/review-quiz' ? ( */}
              {location.pathname.includes('review-quiz') ||
              location.pathname.includes('full-length-exam') ? (
                <FiSettings className="quiz-icons cursor-pointer" />
              ) : (
                <CDropdown alignment="end">
                  <CDropdownToggle className="border-none flex p-0 setting-toggle">
                    <FiSettings className="quiz-icons cursor-pointer" />
                  </CDropdownToggle>
                  <CDropdownMenu className="p-3">
                    <CFormSwitch
                      label="Timer"
                      id="formSwitchCheckChecked"
                      onChange={() => setIsTimer((prevCheck) => !prevCheck)}
                      defaultChecked={isTimer ? true : false}
                    />
                  </CDropdownMenu>
                </CDropdown>
              )}
              <button
                className="flex items-center quiz-icons cursor-pointer"
                onClick={() => {
                  setSidebarOpen((prevState) => !prevState)
                  setIsOpen(false) // Set isOpen to false when the button is clicked
                }}
              >
                {sidebarOpen ? <BiX size={32} /> : <BiTable size={32} />}
              </button>
            </CHeaderNav>
          </div>
        )}
        <div className="hidden sm:flex">
          <CHeaderNav className="">
            <CNavItem>
              <CNavLink
                as={NavLink}
                className={`flex flex-col justify-center items-center mr-2 ${showQues && currentQuestion >= 1 ? '' : 'opacity-0'}`}
                disabled={showQues && currentQuestion >= 1 ? false : true}
                onClick={handlePrevQuestion}
              >
                <BiLeftArrow className="quiz-icons" />
                <span className="text-[#ffffffde]">Previous</span>
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                as={NavLink}
                className={`flex flex-col justify-center items-center ${showQues && currentQuestion + 1 != totalQues ? '' : 'opacity-0'}`}
                disabled={showQues && currentQuestion + 1 != totalQues ? false : true}
                onClick={handleNextQuestion}
              >
                <BiRightArrow className="quiz-icons" />
                <span className="text-[#ffffffde]">Next</span>
              </CNavLink>
            </CNavItem>
          </CHeaderNav>
        </div>
        <div className="hidden sm:flex">
          <CHeaderNav className="items-center">
            {fullscreen ? (
              <BiExitFullscreen
                className="quiz-icons hidden sm:flex mr-2 cursor-pointer"
                onClick={toggleFullscreen}
              />
            ) : (
              <BiFullscreen
                className="hidden md:flex quiz-icons mr-2 cursor-pointer"
                onClick={toggleFullscreen}
              />
            )}
            <CPopover content="Please email ajmonics@gmail.com" placement="bottom">
              <CButton color="link p-0">
                <BiSolidHelpCircle className="quiz-icons mr-2 cursor-pointer" />
              </CButton>
            </CPopover>
            <div onClick={() => setShowNotes((prevCheck) => !prevCheck)}>
              <img src={noteIcon} alt="notes icon" className="mr-2 cursor-pointer" />
            </div>
            <FcCalculator className="quiz-icons mr-2 cursor-pointer" onClick={toggleCalculator} />
            <BiZoomIn
              className="quiz-icons mr-2 cursor-pointer"
              onClick={() => setFontSize(fontSize + 1)}
            />
            {fontSize > 16 && showQues ? (
              <BiZoomOut
                className="quiz-icons mr-2 cursor-pointer"
                onClick={() => setFontSize(fontSize - 1)}
              />
            ) : (
              ''
            )}
            {/* {location.pathname == '/review-quiz' ? ( */}
            {location.pathname.includes('review-quiz') ||
            location.pathname.includes('full-length-exam') ? (
              <FiSettings className="quiz-icons cursor-pointer" />
            ) : (
              <CDropdown alignment="end">
                <CDropdownToggle className="border-none flex p-0 setting-toggle">
                  <FiSettings className="quiz-icons cursor-pointer" />
                </CDropdownToggle>
                <CDropdownMenu className="p-3">
                  <CFormSwitch
                    label="Timer"
                    id="formSwitchCheckChecked"
                    onChange={() => setIsTimer((prevCheck) => !prevCheck)}
                    defaultChecked={isTimer ? true : false}
                  />
                </CDropdownMenu>
              </CDropdown>
            )}
            {/* <FiSettings className="quiz-icons cursor-pointer" /> */}
            {/* New Sidebar Button */}
            <button
              className="flex items-center quiz-icons mx-2 cursor-pointer"
              onClick={() => setSidebarOpen((prevState) => !prevState)}
            >
              {sidebarOpen ? <BiX size={42} /> : <BiTable size={32} />}
            </button>
          </CHeaderNav>
        </div>
      </CHeader>{' '}
      {showCalculator && (
        <div className="fixed bottom-20 sm:right-0 -right-20  z-50">
          <Calculator />
        </div>
      )}
      {showNotes && <ReactStickies notes={notes} onChange={onChange} onSave={onSave} />}
      {/* comment modal */}
      <CModal
        alignment="center"
        visible={commentModal}
        onClose={() => setCommentModal(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle id="VerticallyCenteredExample">Add Comment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CRow className="mb-3">
              <CCol md={12}>
                <CFormInput
                  label="Comment"
                  type="text"
                  id="comment"
                  value={commentValue}
                  placeholder="Add your comment"
                  onChange={(e) => setCommentValue(e.target.value)}
                />
              </CCol>
            </CRow>
            <p className="text-xs mt-3 text-red-400">{commentError}</p>
          </CForm>
          {error && <span className="text-red-400 my-3">{errorMsg}</span>}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setCommentModal(false)}>
            Close
          </CButton>
          <CButton color="primary" onClick={addComment} disabled={loading ? true : false}>
            {loading ? <CSpinner color="light" size="sm" /> : 'Add'}
          </CButton>
        </CModalFooter>
      </CModal>
      {/* success alert */}
      {success && (
        <CAlert color="success" className="success-alert">
          {successMsg}
        </CAlert>
      )}
      {sidebarOpen && (
        <div className="fixed top-0 right-0 h-full sm:w-full md:w-[50%] bg-gray-800 text-white shadow-lg z-100 transition-transform transform">
          <div className="p-2 flex justify-between items-center border-b border-gray-700">
            <h2 className="text-lg font-semibold">Sidebar</h2>
          </div>

          {/* Tab Navigation */}
          <div className="flex flex-wrap border-b border-gray-700">
            <button
              className={`w-full sm:w-1/4 py-2 text-center ${activeTab === 1 ? 'bg-[#4F4EA0]' : 'hover:bg-[#5654ad]'}`}
              onClick={() => setActiveTab(1)}
            >
              Serum
            </button>
            <button
              className={`w-full sm:w-1/4 py-2 text-center ${activeTab === 2 ? 'bg-[#4F4EA0]' : 'hover:bg-[#5654ad]'}`}
              onClick={() => setActiveTab(2)}
            >
              Cerebrospinal
            </button>
            <button
              className={`w-full sm:w-1/4 py-2 text-center ${activeTab === 3 ? 'bg-[#4F4EA0]' : 'hover:bg-[#5654ad]'}`}
              onClick={() => setActiveTab(3)}
            >
              Blood
            </button>
            <button
              className={`w-full sm:w-1/4 py-2 text-center ${activeTab === 4 ? 'bg-[#4F4EA0]' : 'hover:bg-[#5654ad]'}`}
              onClick={() => setActiveTab(4)}
            >
              Urine And BMI
            </button>
            <button
              className={`sm:flex md:hidden w-full sm:w-1/4 py-2 text-center bg-red-500`}
              onClick={() => setSidebarOpen((prevState) => !prevState)}
            >
              Close{' '}
            </button>
          </div>

          {/* Content Area with Scroll */}
          <div className="p-4 overflow-y-auto max-h-[calc(100vh-112px)]">
            {' '}
            {/* Adjust the max-height value to suit your needs */}
            {activeTab === 1 && (
              <div>
                <div className="text-lg font-semibold mt-2">General Chemistry:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Sodium (Na+)</td>
                      <td className="px-4 py-2">136–146 mEq/L</td>
                      <td className="px-4 py-2">136–146 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Potassium (K+)</td>
                      <td className="px-4 py-2">3.5–5.0 mEq/L</td>
                      <td className="px-4 py-2">3.5–5.0 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Chloride (Cl–)</td>
                      <td className="px-4 py-2">95–105 mEq/L</td>
                      <td className="px-4 py-2">95–105 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Bicarbonate (HCO3–)</td>
                      <td className="px-4 py-2">22–28 mEq/L</td>
                      <td className="px-4 py-2">22–28 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Urea nitrogen</td>
                      <td className="px-4 py-2">7–18 mg/dL</td>
                      <td className="px-4 py-2">2.5–6.4 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Creatinine</td>
                      <td className="px-4 py-2">0.6–1.2 mg/dL</td>
                      <td className="px-4 py-2">53–106 μmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Glucose (Fasting)</td>
                      <td className="px-4 py-2">70–100 mg/dL</td>
                      <td className="px-4 py-2">3.8–5.6 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Glucose (Random, non-fasting)</td>
                      <td className="px-4 py-2">&lt;140 mg/dL</td>
                      <td className="px-4 py-2">&lt;7.77 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Calcium</td>
                      <td className="px-4 py-2">8.4–10.2 mg/dL</td>
                      <td className="px-4 py-2">2.1–2.6 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Magnesium (Mg2+)</td>
                      <td className="px-4 py-2">1.5–2.0 mg/dL</td>
                      <td className="px-4 py-2">0.75–1.0 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Phosphorus (inorganic)</td>
                      <td className="px-4 py-2">3.0–4.5 mg/dL</td>
                      <td className="px-4 py-2">1.0–1.5 mmol/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Heptic:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Alanine aminotransferase (ALT)</td>
                      <td className="px-4 py-2">10–40 U/L</td>
                      <td className="px-4 py-2">10–40 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Aspartate aminotransferase (AST)</td>
                      <td className="px-4 py-2">12–38 U/L</td>
                      <td className="px-4 py-2">12–38 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Alkaline phosphatase</td>
                      <td className="px-4 py-2">25–100 U/L</td>
                      <td className="px-4 py-2">25–100 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Amylase</td>
                      <td className="px-4 py-2">25–125 U/L</td>
                      <td className="px-4 py-2">25–125 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Bilirubin, total // direct</td>
                      <td className="px-4 py-2">0.1–1.0 mg/dL // 0.0–0.3 mg/dL</td>
                      <td className="px-4 py-2">2–17 μmol/L // 0–5 μmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Proteins, total</td>
                      <td className="px-4 py-2">6.0–7.8 g/dL</td>
                      <td className="px-4 py-2">60–78 g/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Albumin</td>
                      <td className="px-4 py-2">3.5–5.5 g/dL</td>
                      <td className="px-4 py-2">35–55 g/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Globulin</td>
                      <td className="px-4 py-2">2.3–3.5 g/dL</td>
                      <td className="px-4 py-2">23–35 g/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Heptic:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Cholesterol Total (Normal)</td>
                      <td className="px-4 py-2">&lt;200 mg/dL</td>
                      <td className="px-4 py-2">&lt;5.2 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Cholesterol Total (High)</td>
                      <td className="px-4 py-2">&gt;240 mg/dL</td>
                      <td className="px-4 py-2">&gt;6.2 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">HDL</td>
                      <td className="px-4 py-2">40–60 mg/dL</td>
                      <td className="px-4 py-2">1.0–1.6 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">LDL</td>
                      <td className="px-4 py-2">&lt;160 mg/dL</td>
                      <td className="px-4 py-2">&lt;4.2 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Triglycerides (Normal)</td>
                      <td className="px-4 py-2">&lt;150 mg/dL</td>
                      <td className="px-4 py-2">&lt;1.70 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Triglycerides (Borderline)</td>
                      <td className="px-4 py-2">151–199 mg/dL</td>
                      <td className="px-4 py-2">1.71–2.25 mmol/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Iron Studies:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Ferritin (Male)</td>
                      <td className="px-4 py-2">20–250 ng/mL</td>
                      <td className="px-4 py-2">20–250 μg/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Ferritin (Female)</td>
                      <td className="px-4 py-2">10–120 ng/mL</td>
                      <td className="px-4 py-2">10–120 μg/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Iron (Male)</td>
                      <td className="px-4 py-2">65–175 µg/dL</td>
                      <td className="px-4 py-2">11.6–31.3 μmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Iron (Female)</td>
                      <td className="px-4 py-2">50–170 µg/dL</td>
                      <td className="px-4 py-2">9.0–30.4 μmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Total iron-binding capacity</td>
                      <td className="px-4 py-2">250–400 µg/dL</td>
                      <td className="px-4 py-2">44.8–71.6 μmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Transferrin</td>
                      <td className="px-4 py-2">200–360 mg/dL</td>
                      <td className="px-4 py-2">2.0–3.6 g/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Endocrine:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Follicle-stimulating hormone (Male)</td>
                      <td className="px-4 py-2">4–25 mIU/mL</td>
                      <td className="px-4 py-2">4–25 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">
                        Follicle-stimulating hormone (Female, premenopause)
                      </td>
                      <td className="px-4 py-2">4–30 mIU/mL</td>
                      <td className="px-4 py-2">4–30 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">
                        Follicle-stimulating hormone (Female, midcycle peak)
                      </td>
                      <td className="px-4 py-2">10–90 mIU/mL</td>
                      <td className="px-4 py-2">10–90 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">
                        Follicle-stimulating hormone (Female, postmenopause)
                      </td>
                      <td className="px-4 py-2">40–250 mIU/mL</td>
                      <td className="px-4 py-2">40–250 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Luteinizing hormone (Male)</td>
                      <td className="px-4 py-2">6–23 mIU/mL</td>
                      <td className="px-4 py-2">6–23 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Luteinizing hormone (Female, follicular phase)</td>
                      <td className="px-4 py-2">5–30 mIU/mL</td>
                      <td className="px-4 py-2">5–30 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Luteinizing hormone (Female, midcycle)</td>
                      <td className="px-4 py-2">75–150 mIU/mL</td>
                      <td className="px-4 py-2">75–150 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Luteinizing hormone (Female, postmenopause)</td>
                      <td className="px-4 py-2">30–200 mIU/mL</td>
                      <td className="px-4 py-2">30–200 IU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Growth hormone - arginine stimulation (Fasting)</td>
                      <td className="px-4 py-2">&lt;5 ng/mL</td>
                      <td className="px-4 py-2">&lt;5 μg/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">
                        Growth hormone - arginine stimulation (Provocative stimuli)
                      </td>
                      <td className="px-4 py-2">&gt;7 ng/mL</td>
                      <td className="px-4 py-2">&gt;7 μg/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Prolactin (hPRL) (Male)</td>
                      <td className="px-4 py-2">&lt;17 ng/mL</td>
                      <td className="px-4 py-2">&lt;17 μg/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Prolactin (hPRL) (Female)</td>
                      <td className="px-4 py-2">&lt;25 ng/mL</td>
                      <td className="px-4 py-2">&lt;25 μg/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Cortisol (0800 h)</td>
                      <td className="px-4 py-2">5–23 μg/dL</td>
                      <td className="px-4 py-2">138–635 nmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Cortisol (1600 h)</td>
                      <td className="px-4 py-2">3–15 μg/dL</td>
                      <td className="px-4 py-2">82–413 nmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Cortisol (2000 h)</td>
                      <td className="px-4 py-2">&lt;50% of 0800 h</td>
                      <td className="px-4 py-2">Fraction of 0800 h: &lt;0.50</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">TSH</td>
                      <td className="px-4 py-2">0.4–4.0 μU/mL</td>
                      <td className="px-4 py-2">0.4–4.0 mIU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Triiodothyronine (T3) (RIA)</td>
                      <td className="px-4 py-2">100–200 ng/dL</td>
                      <td className="px-4 py-2">1.5–3.1 nmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Triiodothyronine (T3) resin uptake</td>
                      <td className="px-4 py-2">25%–35%</td>
                      <td className="px-4 py-2">0.25–0.35</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Thyroxine (T4)</td>
                      <td className="px-4 py-2">5–12 μg/dL</td>
                      <td className="px-4 py-2">64–155 nmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Free T4</td>
                      <td className="px-4 py-2">0.9–1.7 ng/dL</td>
                      <td className="px-4 py-2">12.0–21.9 pmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Thyroidal iodine (123I) uptake</td>
                      <td className="px-4 py-2">8%–30% of administered dose/24 h</td>
                      <td className="px-4 py-2">0.08–0.30/24 h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Intact PTH</td>
                      <td className="px-4 py-2">10–60 pg/mL</td>
                      <td className="px-4 py-2">10–60 ng/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">17-Hydroxycorticosteroids (Male)</td>
                      <td className="px-4 py-2">3.0–10.0 mg/24 h</td>
                      <td className="px-4 py-2">8.2–27.6 μmol/24 h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">17-Hydroxycorticosteroids (Female)</td>
                      <td className="px-4 py-2">2.0–8.0 mg/24 h</td>
                      <td className="px-4 py-2">5.5–22.0 μmol/24 h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">17-Ketosteroids, total (Male)</td>
                      <td className="px-4 py-2">8–20 mg/24 h</td>
                      <td className="px-4 py-2">28–70 μmol/24 h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">17-Ketosteroids, total (Female)</td>
                      <td className="px-4 py-2">6–15 mg/24 h</td>
                      <td className="px-4 py-2">21–52 μmol/24 h</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Immunoglobulins:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">IgA</td>
                      <td className="px-4 py-2">76–390 mg/dL</td>
                      <td className="px-4 py-2">0.76–3.90 g/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">IgE</td>
                      <td className="px-4 py-2">0–380 IU/mL</td>
                      <td className="px-4 py-2">0–380 kIU/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">IgG</td>
                      <td className="px-4 py-2">650–1500 mg/dL</td>
                      <td className="px-4 py-2">6.5–15.0 g/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">IgM</td>
                      <td className="px-4 py-2">50–300 mg/dL</td>
                      <td className="px-4 py-2">0.5–3.0 g/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Other Serums:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Creatinine clearance (Male)</td>
                      <td className="px-4 py-2">97–137 mL/min</td>
                      <td className="px-4 py-2">97–137 mL/min</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Creatinine clearance (Female)</td>
                      <td className="px-4 py-2">88–128 mL/min</td>
                      <td className="px-4 py-2">88–128 mL/min</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Creatine kinase (Male)</td>
                      <td className="px-4 py-2">25–90 U/L</td>
                      <td className="px-4 py-2">25–90 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Creatine kinase (Female)</td>
                      <td className="px-4 py-2">10–70 U/L</td>
                      <td className="px-4 py-2">10–70 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Lactate dehydrogenase</td>
                      <td className="px-4 py-2">45–200 U/L</td>
                      <td className="px-4 py-2">45–200 U/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Osmolality</td>
                      <td className="px-4 py-2">275–295 mOsmol/kg H2O</td>
                      <td className="px-4 py-2">275–295 mOsmol/kg H2O</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Uric acid</td>
                      <td className="px-4 py-2">3.0–8.2 mg/dL</td>
                      <td className="px-4 py-2">0.18–0.48 mmol/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Gases, Arterial Blood (Room Air):</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Serum</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">PO2</td>
                      <td className="px-4 py-2">75–105 mm Hg</td>
                      <td className="px-4 py-2">10.0–14.0 kPa</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">PCO2</td>
                      <td className="px-4 py-2">33–45 mm Hg</td>
                      <td className="px-4 py-2">4.4–5.9 kPa</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">pH</td>
                      <td className="px-4 py-2">7.35–7.45</td>
                      <td className="px-4 py-2">[H+] 36–44 nmol/L</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mt-20"></div>
              </div>
            )}
            {activeTab === 2 && (
              <div>
                <div className="text-lg font-semibold mt-2">Cerebrospinal Fluid:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Fluid</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Cell count</td>
                      <td className="px-4 py-2">0–5/mm3</td>
                      <td className="px-4 py-2">0–5 × 10⁶/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Chloride</td>
                      <td className="px-4 py-2">118–132 mEq/L</td>
                      <td className="px-4 py-2">118–132 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Gamma globulin</td>
                      <td className="px-4 py-2">3%–12% total proteins</td>
                      <td className="px-4 py-2">0.03–0.12</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Glucose</td>
                      <td className="px-4 py-2">40–70 mg/dL</td>
                      <td className="px-4 py-2">2.2–3.9 mmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Pressure</td>
                      <td className="px-4 py-2">70–180 mm H2O</td>
                      <td className="px-4 py-2">70–180 mm H2O</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Proteins, total</td>
                      <td className="px-4 py-2">&lt;40 mg/dL</td>
                      <td className="px-4 py-2">&lt;0.40 g/L</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {activeTab === 3 && (
              <div>
                <div className="text-lg font-semibold mt-2">Hematologic:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Complete Blood Count</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Hematocrit (Male)</td>
                      <td className="px-4 py-2">41%–53%</td>
                      <td className="px-4 py-2">0.41–0.53</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Hematocrit (Female)</td>
                      <td className="px-4 py-2">36%–46%</td>
                      <td className="px-4 py-2">0.36–0.46</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Hemoglobin, blood (Male)</td>
                      <td className="px-4 py-2">13.5–17.5 g/dL</td>
                      <td className="px-4 py-2">135–175 g/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Hemoglobin, blood (Female)</td>
                      <td className="px-4 py-2">Female: 12.0–16.0 g/dL</td>
                      <td className="px-4 py-2">120–160 g/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Mean corpuscular hemoglobin (MCH)</td>
                      <td className="px-4 py-2">25–35 pg/cell</td>
                      <td className="px-4 py-2">0.39–0.54 fmol/cell</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Mean corpuscular hemoglobin conc. (MCHC)</td>
                      <td className="px-4 py-2">31%–36% Hb/cell</td>
                      <td className="px-4 py-2">4.8–5.6 mmol Hb/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Mean corpuscular volume (MCV)</td>
                      <td className="px-4 py-2">80–100 μm3</td>
                      <td className="px-4 py-2">80–100 fL</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Plasma Volume (Male)</td>
                      <td className="px-4 py-2">25–43 mL/kg</td>
                      <td className="px-4 py-2">0.025–0.043 L/kg</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Plasma Volume (Female)</td>
                      <td className="px-4 py-2">28–45 mL/kg</td>
                      <td className="px-4 py-2">0.028–0.045 L/kg</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Red cell volume (Male)</td>
                      <td className="px-4 py-2">20–36 mL/kg</td>
                      <td className="px-4 py-2">0.020–0.036 L/kg</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Red cell volume (Female)</td>
                      <td className="px-4 py-2">19–31 mL/kg</td>
                      <td className="px-4 py-2">0.019–0.031 L/kg</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Leukocyte count (WBC)</td>
                      <td className="px-4 py-2">4500–11,000/mm3</td>
                      <td className="px-4 py-2">4.5–11.0 × 10⁹/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Neutrophils, segmented</td>
                      <td className="px-4 py-2">54%–62%</td>
                      <td className="px-4 py-2">0.54–0.62</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Neutrophils, bands</td>
                      <td className="px-4 py-2">3%–5%</td>
                      <td className="px-4 py-2">0.03–0.05</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Lymphocytes</td>
                      <td className="px-4 py-2">25%–33%</td>
                      <td className="px-4 py-2">0.25–0.33</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Monocytes</td>
                      <td className="px-4 py-2">3%–7%</td>
                      <td className="px-4 py-2">0.03–0.07</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Eosinophils</td>
                      <td className="px-4 py-2">1%–3%</td>
                      <td className="px-4 py-2">0.01–0.03</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Basophils</td>
                      <td className="px-4 py-2">0%–0.75%</td>
                      <td className="px-4 py-2">0.00–0.0075</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Platelet count</td>
                      <td className="px-4 py-2">150,000–400,000/mm3</td>
                      <td className="px-4 py-2">150–400 × 10⁹/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Coagulation:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Complete Blood Count</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Partial thromboplastin time (PTT) (activated)</td>
                      <td className="px-4 py-2">25–40 seconds</td>
                      <td className="px-4 py-2">25–40 seconds</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Prothrombin time (PT)</td>
                      <td className="px-4 py-2">11–15 seconds</td>
                      <td className="px-4 py-2">11–15 seconds</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">D-Dimer</td>
                      <td className="px-4 py-2">≤250 ng/mL</td>
                      <td className="px-4 py-2">≤1.4 nmol/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Other, Hematologic:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Complete Blood Count</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Reticulocyte count</td>
                      <td className="px-4 py-2">0.5%–1.5%</td>
                      <td className="px-4 py-2">0.005–0.015</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Erythrocyte count (RBC) (Male)</td>
                      <td className="px-4 py-2">4.3–5.9 million/mm3</td>
                      <td className="px-4 py-2">
                        4.3–5.9 × 10<sup>12</sup>/L
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Erythrocyte count (RBC) (Female)</td>
                      <td className="px-4 py-2">3.5–5.5 million/mm3</td>
                      <td className="px-4 py-2">
                        3.5–5.5 × 10<sup>12</sup>/L
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">
                        Erythrocyte sedimentation rate (Westergren) (Male)
                      </td>
                      <td className="px-4 py-2">0–15 mm/h</td>
                      <td className="px-4 py-2">0–15 mm/h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">
                        Erythrocyte sedimentation rate (Westergren) (Female)
                      </td>
                      <td className="px-4 py-2">0–20 mm/h</td>
                      <td className="px-4 py-2">0–20 mm/h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">CD4+ T-lymphocyte count</td>
                      <td className="px-4 py-2">≥500/mm3</td>
                      <td className="px-4 py-2">
                        ≥0.5 × 10<sup>9</sup>/L
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Troponin I</td>
                      <td className="px-4 py-2">≤0.04 ng/mL</td>
                      <td className="px-4 py-2">≤0.04 µg/L</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">Endocrine:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Complete Blood Count</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Hemoglobin A1c</td>
                      <td className="px-4 py-2">≤6%</td>
                      <td className="px-4 py-2">≤42 mmol/mol</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {activeTab === 4 && (
              <div>
                <div className="text-lg font-semibold mt-2">Urine:</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Fluid</th>
                      <th className="px-4 py-2 border-b">Reference Range</th>
                      <th className="px-4 py-2 border-b">SI Reference Intervals</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Calcium</td>
                      <td className="px-4 py-2">100–300 mg/24 h</td>
                      <td className="px-4 py-2">2.5–7.5 mmol/24 h</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Osmolality</td>
                      <td className="px-4 py-2">50–1200 mOsmol/kg H2O</td>
                      <td className="px-4 py-2">50–1200 mOsmol/kg H2O</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Oxalate</td>
                      <td className="px-4 py-2">8–40 μg/mL</td>
                      <td className="px-4 py-2">90–445 μmol/L</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Proteins, total</td>
                      <td className="px-4 py-2">&lt;150 mg/24 h</td>
                      <td className="px-4 py-2">&lt;0.15 g/24 h</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-lg font-semibold mt-2">BODY MASS INDEX (BMI):</div>
                <table className="min-w-full bg-gray-700 text-white border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border-b">Age Group</th>
                      <th className="px-4 py-2 border-b">BMI Range (kg/m²)</th>
                      <th className="px-4 py-2 border-b">Category</th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    <tr>
                      <td className="px-4 py-2">Child (2-18 years)</td>
                      <td className="px-4 py-2">&lt;18.5</td>
                      <td className="px-4 py-2">Underweight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Child (2-18 years)</td>
                      <td className="px-4 py-2">18.5–24.9</td>
                      <td className="px-4 py-2">Normal weight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Child (2-18 years)</td>
                      <td className="px-4 py-2">25–29.9</td>
                      <td className="px-4 py-2">Overweight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Child (2-18 years)</td>
                      <td className="px-4 py-2">≥30</td>
                      <td className="px-4 py-2">Obese</td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2">Young Adult (19–39 years)</td>
                      <td className="px-4 py-2">&lt;18.5</td>
                      <td className="px-4 py-2">Underweight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Young Adult (19–39 years)</td>
                      <td className="px-4 py-2">18.5–24.9</td>
                      <td className="px-4 py-2">Normal weight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Young Adult (19–39 years)</td>
                      <td className="px-4 py-2">25–29.9</td>
                      <td className="px-4 py-2">Overweight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Young Adult (19–39 years)</td>
                      <td className="px-4 py-2">≥30</td>
                      <td className="px-4 py-2">Obese</td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2">Old (40+ years)</td>
                      <td className="px-4 py-2">&lt;18.5</td>
                      <td className="px-4 py-2">Underweight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Old (40+ years)</td>
                      <td className="px-4 py-2">18.5–24.9</td>
                      <td className="px-4 py-2">Normal weight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Old (40+ years)</td>
                      <td className="px-4 py-2">25–29.9</td>
                      <td className="px-4 py-2">Overweight</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">Old (40+ years)</td>
                      <td className="px-4 py-2">≥30</td>
                      <td className="px-4 py-2">Obese</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default QuizHeader
