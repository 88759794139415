import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CCol, CRow, CButton, CForm, CFormSelect, CSpinner, CAlert } from '@coreui/react'
import { API_URL } from 'src/store'
import { useForm } from 'react-hook-form'
import AdminLayout from 'src/layout/AdminLayout'
import { step1Categories, step2Categories, step3Categories } from 'src/usmleData'

const DownloadQuestions = () => {
  const navigate = useNavigate()
  const [token, setToken] = useState(localStorage.getItem('token') || '')
  const role = localStorage.getItem('user') || ''
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      usmleStep: '',
      usmleCategory: '',
    },
  })

  const step = watch('usmleStep')

  useEffect(() => {
    const getToken = localStorage.getItem('token')
    if (getToken && role === 'admin') {
      setToken(getToken)
    } else {
      navigate('/login')
    }
  }, [role, navigate])

  const downloadQuestions = async (data) => {
    setLoading(true)
    setError(false)
    setErrorMsg('')
    try {
      const response = await fetch(`${API_URL}download-questions`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usmleStep: data.usmleStep,
          USMLE: data.usmleCategory,
        }),
      })

      if (!response.ok) {
        const errorData = await response.json()
        setError(true)
        setErrorMsg(errorData.message || 'Failed to download questions.')
        setLoading(false)
        return
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `Step${data.usmleStep}(${data.usmleCategory}).xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)

      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    } catch (error) {
      console.error(error)
      setError(true)
      setErrorMsg('An unexpected error occurred.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <AdminLayout>
      <div className="mx-4">
        <p className="text-2xl">Download Questions</p>
        <CForm onSubmit={handleSubmit(downloadQuestions)} className="my-3">
          <CRow className="mb-3">
            <CCol md={12}>
              <CFormSelect
                label="USMLE Step"
                aria-label="USMLE Step"
                {...register('usmleStep', { required: true })}
                feedback="Please select USMLE Step"
                invalid={errors.usmleStep ? true : false}
              >
                <option value="">Select USMLE Step</option>
                <option value="1">Step 1</option>
                <option value="2">Step 2</option>
                <option value="3">Step 3</option>
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol md={12}>
              <CFormSelect
                label="USMLE Category"
                aria-label="USMLE Category"
                {...register('usmleCategory', { required: true })}
                feedback="Please select USMLE Category."
                invalid={errors.usmleCategory ? true : false}
              >
                <option value="">Select USMLE Category</option>
                {step === '1' &&
                  step1Categories.map((category, idx) => (
                    <option key={idx} value={category}>
                      {category}
                    </option>
                  ))}
                {step === '2' &&
                  step2Categories.map((category, idx) => (
                    <option key={idx} value={category}>
                      {category}
                    </option>
                  ))}
                {step === '3' &&
                  step3Categories.map((category, idx) => (
                    <option key={idx} value={category}>
                      {category}
                    </option>
                  ))}
              </CFormSelect>
            </CCol>
          </CRow>
          {error && <CAlert color="danger">{errorMsg}</CAlert>}
          <CButton color="primary" type="submit" disabled={loading} className="mt-3">
            {loading ? <CSpinner size="sm" /> : 'Download'}
          </CButton>
        </CForm>
      </div>
      {success && (
        <CAlert color="success" className="mt-3">
          Questions downloaded successfully.
        </CAlert>
      )}
    </AdminLayout>
  )
}

export default DownloadQuestions
