import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CSpinner,
  CFormTextarea,
  CAlert,
  CFormCheck,
  CProgress,
  CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilFilter, cilPencil, cilTrash } from '@coreui/icons'
import { API_URL } from 'src/store'
import { useForm } from 'react-hook-form'
import AdminLayout from 'src/layout/AdminLayout'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
//////////
import JoditEditor from 'jodit-react'
import { RiArrowLeftSLine, RiArrowRightSLine, RiEyeLine } from 'react-icons/ri'
/// video player
import '../../../../node_modules/video-react/dist/video-react.css' // import css
import { Player } from 'video-react'
import DropBox from 'src/components/admin/DropBox'
import { step1Categories, step2Categories, step3Categories } from 'src/usmleData'
import ReactPaginate from 'react-paginate'
import { FaArrowUp, FaCheck, FaCheckDouble, FaCross } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { BiDotsVertical } from 'react-icons/bi'

const ManageQuiz = () => {
  const editor = useRef(null)
  const options = ['bold', 'italic', 'underline', 'image', 'table']
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: 'Start typing...',
      defaultActionOnPaste: 'insert_as_text',
      defaultLineHeight: 1.5,
      enter: 'div',
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: true,
    }),
    [],
  )
  //////
  const navigate = useNavigate()
  const [allQuestion, setAllQuestion] = useState([])
  const [filteredQuestion, setFilteredQuestion] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [imgLoader, setImgLoader] = useState(false)
  const [img2Loader, setImg2Loader] = useState(false)
  const [questionId, setQuestionId] = useState('')
  const [srNo, setSrNo] = useState('')
  const [image, setImage] = useState('')
  const [image2, setImage2] = useState('')
  const [videoLoader, setVideoLoader] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')
  const [video, setVideo] = useState('')
  const [prevVideo, setPrevVideo] = useState(false)
  // const [usmleStep, setUsmleStep] = useState('')
  // const [usmleCategory, setUsmleCategory] = useState('')
  // const [question, setQuestion] = useState('')
  // const [op1, setOp1] = useState('')
  // const [op2, setOp2] = useState('')
  // const [op3, setOp3] = useState('')
  // const [op4, setOp4] = useState('')
  // const [correct, setCorrect] = useState('')
  const [error, setErrorr] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [qError, setQError] = useState(false)
  const [expError, setExpError] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [op6, setOp6] = useState('')
  const [op1Exp, setOp1Exp] = useState('')
  const [op2Exp, setOp2Exp] = useState('')
  const [op3Exp, setOp3Exp] = useState('')
  const [op4Exp, setOp4Exp] = useState('')
  const [op5Exp, setOp5Exp] = useState('')
  const [op6Exp, setOp6Exp] = useState('')
  const [deleteIds, setDeleteIds] = useState([])
  const [token, setToken] = useState(localStorage.getItem('token') || '')
  const role = localStorage.getItem('user') || ''
  const [filterUsmle, setFilterUsmle] = useState('')
  const [filterCategory, setFilterCategory] = useState('')
  const [showFilteredResult, setShowFilteredResult] = useState(false)
  const [file, setFile] = useState()
  const [fileEnter, setFileEnter] = useState(false)
  const [showCheck, setShowCheck] = useState(false)
  const [showMoveCheck, setShowMoveCheck] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [filtercurrentPage, setfilterCurrentPage] = useState(1)
  const [filtertotal, setfilterTotal] = useState(0)
  const [filtertotalPages, setfilterTotalPages] = useState(0)
  const [filterpageSize, setfilterPageSize] = useState(0)
  const expmodules = {
    toolbar: [['bold', 'italic', 'underline', 'image']],
  }
  const expformats = ['bold', 'italic', 'underline', 'image']
  const modules = {
    toolbar: [['bold', 'italic', 'underline']],
  }
  const formats = ['bold', 'italic', 'underline']
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      usmleStep: '',
      usmleCategory: '',
      question: '',
      op1: '',
      op2: '',
      op3: '',
      op4: '',
      op5: '',
      correct: '',
      explaination: '',
    },
  })

  const stepSelected = watch('usmleStep')
  const option1 = watch('op1')
  const option2 = watch('op2')
  const option3 = watch('op3')
  const option4 = watch('op4')
  const option5 = watch('op5')
  const option6 = op6

  useEffect(() => {
    getAllQuest(1)
    const getToken = localStorage.getItem('token')
    if (getToken && role == 'admin') {
      setToken(getToken)
    } else {
      navigate('/login')
    }
  }, [])
  useEffect(() => {
    console.log(progress)
  }, [progress])
  useEffect(() => {
    getQuestion()
  }, [questionId])
  useEffect(() => {
    console.log('delete id array is changed')
  }, [deleteIds])

  const getAllQuest = (pageNo) => {
    console.log('page no', pageNo, 'current page', currentPage)
    setLoader(true)
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(API_URL + 'paginated-mcqs?page=' + pageNo, requestOptions)
      .then((response) => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader()
              read()
              function read() {
                reader.read().then((progressEvent) => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength

                  const percentageComplete = Math.round((loaded / contentLength) * 100)
                  setProgress(percentageComplete)

                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            },
          }),
        )
      })
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        setLoader(false)
        if (result.data) {
          setAllQuestion(result.data)
          setCurrentPage(result.pagination?.page)
          setTotal(result.pagination?.total)
          setTotalPages(result.pagination?.totalPages)
          setPageSize(result.pagination?.limit)
        }
      })
      .catch((error) => {
        console.error(error)
        setLoader(false)
      })
  }
  const addQuestion = (data) => {
    // console.log('add function called', data, '...', stepSelected)
    if (getValues('question') == '') {
      setError('question', true)
      // console.log('question not entered')
    }
    setIsLoading(true)
    setErrorr(false)
    setErrorMsg('')
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)

    const formdata = new FormData()
    formdata.append('usmleStep', data.usmleStep)
    formdata.append('USMLE', data.usmleCategory)
    formdata.append('question', data.question)
    formdata.append('optionOne', data.op1)
    formdata.append('correctAnswer', data.correct)
    formdata.append('questionExplanation', data.explaination)
    formdata.append('image', image)
    formdata.append('imageTwo', image2)
    formdata.append('video', video)
    formdata.append('optionTwo', data.op2)
    formdata.append('optionThree', data.op3)
    formdata.append('optionFour', data.op4)
    formdata.append('optionFive', data.op5)
    if (op6) {
      formdata.append('optionSix', op6)
    }
    if (op1Exp) {
      formdata.append('optionOneExplanation', op1Exp)
    }
    if (op2Exp) {
      formdata.append('optionTwoExplanation', op2Exp)
    }
    if (op3Exp) {
      formdata.append('optionThreeExplanation', op3Exp)
    }
    if (op4Exp) {
      formdata.append('optionFourExplanation', op4Exp)
    }
    if (op5Exp) {
      formdata.append('optionFiveExplanation', op5Exp)
    }
    if (op6Exp) {
      formdata.append('optionSixExplanation', op6Exp)
    }
    const requestOptions = {
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(API_URL + 'add-mcqs', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        setIsLoading(false)
        if (result.success) {
          setAddModal(false)
          getAllQuest(currentPage)
          reset({})
          setImage('')
          setImage2('')
          setVideo('')
          setVideoSrc('')
          setOp6('')
          setOp6Exp('')
          setSuccess(true)
          setSuccessMsg('Question added successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
        }
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }
  const getQuestion = () => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(API_URL + 'mcq/' + questionId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log('ques detail', result)
        if (result.data) {
          reset({
            usmleStep: result.data.usmleStep,
            usmleCategory: result.data.USMLE,
            question: result.data.question,
            explaination: result.data.questionExplanation,
            op1: result.data.optionOne,
            op2: result.data.optionTwo,
            op3: result.data.optionThree,
            op4: result.data.optionFour,
            op5: result.data.optionFive,
            correct: result.data.correctAnswer,
          })
          setOp6(result.data.optionSix)
          setOp1Exp(result.data.optionOneExplanation)
          setOp2Exp(result.data.optionTwoExplanation)
          setOp3Exp(result.data.optionThreeExplanation)
          setOp4Exp(result.data.optionFourExplanation)
          setOp5Exp(result.data.optionFiveExplanation)
          setOp6Exp(result.data.optionSixExplanation)
          setImage(result.data.image)
          setImage2(result.data.imageTwo)
          if (result.data.video != null) {
            setVideoSrc(result.data.video)
            setVideo(result.data.video)
            setPrevVideo(true)
          } else {
            setPrevVideo(false)
          }
        }
      })
      .catch((error) => console.log('error', error))
  }

  const bulkdeleteQuestion = () => {
    setIsLoading(true)
    setErrorr(false)
    setErrorMsg('')
    // console.log(questionId)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    myHeaders.append('Content-Type', 'application/json')

    const raw = JSON.stringify({
      mcqIds: deleteIds,
    })

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
    }

    fetch(API_URL + 'delete-multiple-mcqs', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        setIsLoading(false)
        if (result.success) {
          setBulkDeleteModal(false)
          setShowFilteredResult(false)
          getAllQuest(currentPage)
          setSuccess(true)
          setDeleteIds([])
          setSuccessMsg(result.message)
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoading(false)
      })
  }
  const deleteQuestion = () => {
    setIsLoading(true)
    setErrorr(false)
    setErrorMsg('')
    var myHeaders = new Headers()
    myHeaders.append('Authorization', token)

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
    }

    fetch(API_URL + 'delete-mcq/' + questionId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setIsLoading(false)
        if (result.success) {
          setDeleteModal(false)
          getAllQuest(currentPage)
          setSuccess(true)
          setSuccessMsg('Question deleted successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
        }
      })
      .catch((error) => console.log('error', error))
  }
  const editQuestion = (data) => {
    // console.log('edit function called', data)
    setIsLoading(true)
    setErrorr(false)
    setErrorMsg('')
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)

    const formdata = new FormData()
    formdata.append('usmleStep', data.usmleStep)
    formdata.append('USMLE', data.usmleCategory)
    formdata.append('question', data.question)
    formdata.append('optionOne', data.op1)
    formdata.append('correctAnswer', data.correct)
    formdata.append('questionExplanation', data.explaination)
    formdata.append('image', image)
    formdata.append('imageTwo', image2)
    formdata.append('video', video)
    formdata.append('optionTwo', data.op2)
    formdata.append('optionThree', data.op3)
    formdata.append('optionFour', data.op4)
    formdata.append('optionFive', data.op5)
    formdata.append('srNo', srNo)
    if (op6) {
      formdata.append('optionSix', op6)
    }
    if (op1Exp) {
      formdata.append('optionOneExplanation', op1Exp)
    }
    if (op2Exp) {
      formdata.append('optionTwoExplanation', op2Exp)
    }
    if (op3Exp) {
      formdata.append('optionThreeExplanation', op3Exp)
    }
    if (op4Exp) {
      formdata.append('optionFourExplanation', op4Exp)
    }
    if (op5Exp) {
      formdata.append('optionFiveExplanation', op5Exp)
    }
    if (op6Exp) {
      formdata.append('optionSixExplanation', op6Exp)
    }
    const requestOptions = {
      method: 'PUT',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(API_URL + 'edit-mcqs/' + questionId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setAddModal(false)
          setIsLoading(false)
          // getAllQuest(currentPage)
          if (showFilteredResult) {
            const indexToReplace = filteredQuestion.findIndex((ques) => ques._id === questionId)

            if (indexToReplace !== -1) {
              const updatedQuestionSplice = [...filteredQuestion]
              updatedQuestionSplice.splice(indexToReplace, 1, result.data)
              // console.log(updatedQuestionSplice)
              setFilteredQuestion(updatedQuestionSplice)
            }
          } else {
            const indexToReplace = allQuestion.findIndex((ques) => ques._id === questionId)

            if (indexToReplace !== -1) {
              const updatedQuestionSplice = [...allQuestion]
              updatedQuestionSplice.splice(indexToReplace, 1, result.data)
              // console.log(updatedQuestionSplice)
              setAllQuestion(updatedQuestionSplice)
            }
          }
          setQuestionId('')
          setImage('')
          setImage2('')
          setVideo('')
          setVideoSrc('')
          setOp6('')
          setOp6Exp('')
          reset({})
          setSuccess(true)
          setSuccessMsg('Question updated successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }
  const deleteImage = () => {
    // console.log('delete image', questionId)
    setImgLoader(true)
    setErrorr(false)
    setErrorMsg('')
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(API_URL + 'mcq/' + questionId + '/image', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setImgLoader(false)
          getQuestion()
          setSuccess(true)
          setSuccessMsg('Image deleted successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
        }
      })
      .catch((error) => {
        console.error(error)
        setImgLoader(false)
      })
  }
  const deleteImage2 = () => {
    // console.log('delete image', questionId)
    setImg2Loader(true)
    setErrorr(false)
    setErrorMsg('')
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(API_URL + 'mcq/' + questionId + '/imageTwo', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setImg2Loader(false)
          getQuestion()
          setSuccess(true)
          setSuccessMsg('Image deleted successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
        }
      })
      .catch((error) => {
        console.error(error)
        setImg2Loader(false)
      })
  }
  const deleteVideo = () => {
    // console.log('delete video', questionId)
    setVideoLoader(true)
    setErrorr(false)
    setErrorMsg('')
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(API_URL + 'mcq/' + questionId + '/video', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setVideoLoader(false)
          getQuestion()
          setSuccess(true)
          setSuccessMsg('Video deleted successfully')
          setTimeout(() => {
            setSuccess(false)
            setSuccessMsg('')
          }, 3000)
        } else {
          setErrorr(true)
          setErrorMsg(result.message)
        }
      })
      .catch((error) => {
        console.error(error)
        setVideoLoader(false)
      })
  }
  const handleVideoChange = (file) => {
    var reader = new FileReader()
    setVideo(file)
    // console.log(file)
    // var url = URL.createObjectURL(file)
    // setVideoSrc(url)
    // console.log('video url', url)
  }

  const [pageNumberForLater, setPageNumberForLater] = useState()
  const getFilteredQuestions = (pageNo) => {
    // console.log('step', filterUsmle, 'category', filterCategory)

    setPageNumberForLater(pageNo)
    console.log('++++++++++++++++++++++++++++++++++')
    console.log(pageNumberForLater)
    if (filterUsmle == '' && filterCategory == '') {
      // do nothing
    } else {
      setLoader(true)
      const myHeaders = new Headers()
      myHeaders.append('Authorization', token)
      myHeaders.append('Content-Type', 'application/json')

      const raw = JSON.stringify({
        usmleStep: filterUsmle,
        USMLE: filterCategory,
      })
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
      fetch(API_URL + 'filtered-mcqs?page=' + pageNo, requestOptions)
        .then((response) => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0
          return new Response(
            new ReadableStream({
              start(controller) {
                const reader = response.body.getReader()
                read()
                function read() {
                  reader.read().then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close()
                      return
                    }
                    loaded += progressEvent.value.byteLength

                    const percentageComplete = Math.round((loaded / contentLength) * 100)
                    setProgress(percentageComplete)

                    controller.enqueue(progressEvent.value)
                    read()
                  })
                }
              },
            }),
          )
        })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          setLoader(false)
          if (result.data) {
            setShowFilteredResult(true)
            setFilteredQuestion(result.data)
            setfilterCurrentPage(result.pagination?.page)
            setfilterTotal(result.pagination?.total)
            setfilterTotalPages(result.pagination?.totalPages)
            setfilterPageSize(result.pagination?.limit)
          }
        })
        .catch((error) => {
          console.error(error)
          setLoader(false)
        })
    }
    // let filtered_result = []
    // if (filterUsmle && filterCategory) {
    //   filtered_result = allQuestion.filter(
    //     (ques) => ques.usmleStep == filterUsmle && ques.USMLE == filterCategory,
    //   )
    //   setShowFilteredResult(true)
    // } else {
    //   if (filterUsmle) {
    //     filtered_result = allQuestion.filter((ques) => ques.usmleStep == filterUsmle)
    //     setShowFilteredResult(true)
    //   }
    //   if (filterCategory) {
    //     filtered_result = allQuestion.filter((ques) => ques.USMLE == filterCategory)
    //     setShowFilteredResult(true)
    //   }
    // }
    // setFilteredQuestion(filtered_result)
    // console.log(filtered_result)
  }
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      event.target.checked = true
    } else {
      event.target.checked = false
    }
    let newArray = [...deleteIds, event.target.id]
    if (deleteIds.includes(event.target.id)) {
      newArray = newArray.filter((id) => id !== event.target.id)
    }
    setDeleteIds(newArray)
  }
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      let checkboxes = document.querySelectorAll('.checkboxes')
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true
      }, this)
      if (showFilteredResult) {
        let ids = filteredQuestion.map((item) => item._id)
        setDeleteIds(ids)
      } else {
        let ids = allQuestion.map((item) => item._id)
        setDeleteIds(ids)
      }
    } else {
      setDeleteIds([])
      let checkboxes = document.querySelectorAll('.checkboxes')
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false
      }, this)
    }
  }
  const markCorrect = (id) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ userId: 'a1b2c3d4e5f6g7h8i9j0k1l2', testerName: 'admin' }),
      redirect: 'follow',
    }

    fetch(API_URL + 'admin/mark-correct/' + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('result', result, 'ques array', allQuestion)
        // getAllQuest()
        if (result.success) {
          if (showFilteredResult) {
            setSuccessMsg(result.message)

            setFilteredQuestion(
              filteredQuestion.map((ques) => {
                if (ques._id === id) {
                  // Create a *new* object with changes
                  return { ...ques, isCorrect: true }
                } else {
                  // No changes
                  return ques
                }
              }),
            )
          } else {
            setAllQuestion(
              allQuestion.map((ques) => {
                if (ques._id === id) {
                  // Create a *new* object with changes
                  return { ...ques, isCorrect: true }
                } else {
                  // No changes
                  return ques
                }
              }),
            )
          }
        }
      })
      .catch((error) => console.log('error', error))
  }
  const unMarkCorrect = (id) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ userId: 'a1b2c3d4e5f6g7h8i9j0k1l2', testerName: 'admin' }),
      redirect: 'follow',
    }

    fetch(API_URL + 'admin/unmark-correct/' + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('result', result)
        // getAllQuest()
        if (result.success) {
          if (showFilteredResult) {
            setFilteredQuestion(
              filteredQuestion.map((ques) => {
                if (ques._id === id) {
                  // Create a *new* object with changes
                  return { ...ques, isCorrect: false }
                } else {
                  // No changes
                  return ques
                }
              }),
            )
          } else {
            setAllQuestion(
              allQuestion.map((ques) => {
                if (ques._id === id) {
                  // Create a *new* object with changes
                  return { ...ques, isCorrect: false }
                } else {
                  // No changes
                  return ques
                }
              }),
            )
          }
        }
      })
      .catch((error) => console.log('error', error))
  }

  const showNextButton = currentPage - 1 !== totalPages - 1
  const showPrevButton = currentPage - 1 !== 0
  const showFilterNextButton = filtercurrentPage - 1 !== filtertotalPages - 1
  const showFilterPrevButton = filtercurrentPage - 1 !== 0

  // const [oldSrNo, setOldSrNo] = useState('')
  // const [usmleStep, setUsmleStep] = useState('')
  // const [USMLE, setUSMLE] = useState('')

  const [openDropdownId, setOpenDropdownId] = useState(null)
  const dropdownRef = useRef(null)

  const toggleDropdown = (id) => {
    setOpenDropdownId((prev) => (prev === id ? null : id))
  }
  const toggleCheckBox = () => {
    setShowMoveCheck(true)
    console.log(showMoveCheck)
  }
  const cancelMoveCheck = () => {
    setSelectedQuestions([])
    setShowMoveCheck(false)
    console.log(showMoveCheck)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // const moveUpHandler = () => {
  //   const myHeaders = new Headers()
  //   myHeaders.append('Authorization', token)
  //   myHeaders.append('Content-Type', 'application/json')

  //   const requestOptions = {
  //     method: 'PUT',
  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       srNo: oldSrNo,
  //       usmleStep: usmleStep,
  //       USMLE: USMLE,
  //       newSrNo: oldSrNo - 1,
  //     }),
  //     redirect: 'follow',
  //   }
  //   console.log('+++++++++++++++++++++++++++')
  //   console.log(oldSrNo)
  //   fetch(API_URL + 'edit-sr-no-for-question', requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log('result', result)
  //       // Handle success
  //       if (result.newQuestions) {
  //         const newMCQS = result.newQuestions
  //         console.log('New MCQS:', newMCQS)
  //         setFilteredQuestion(newMCQS)
  //       }
  //       if (result.success) {
  //         setOldSrNo('')
  //         setUsmleStep('')
  //         setUSMLE('')
  //         if (showFilteredResult) {
  //           setFilteredQuestion(
  //             filteredQuestion.map((ques) => {
  //               if (ques.srNo === srNo - 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         } else {
  //           setAllQuestion(
  //             allQuestion.map((ques) => {
  //               if (ques.srNo === srNo - 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         }
  //       }
  //     })
  //     .catch((error) => console.log('error', error))
  //   // setOldSrNo('')
  //   // setUsmleStep('')
  //   // setUSMLE('')
  // }

  // const moveUpHandler = (srNo, usmleStep, USMLE) => {
  //   setErrorMsg('')
  //   setIsLoading(true)
  //   const myHeaders = new Headers()
  //   myHeaders.append('Authorization', token)
  //   myHeaders.append('Content-Type', 'application/json')

  //   const requestOptions = {
  //     method: 'PUT',
  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       srNo: srNo,
  //       usmleStep: usmleStep,
  //       USMLE: USMLE,
  //       newSrNo: srNo - 1, // Decrement srNo
  //     }),
  //     redirect: 'follow',
  //   }

  //   console.log('+++++++++++++++++++++++++++')
  //   console.log('Moving up question:', srNo)

  //   fetch(API_URL + 'edit-sr-no-for-question', requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log('result', result)

  //       // Update questions list with new MCQs
  //       if (result.newQuestions) {
  //         const newMCQS = result.newQuestions
  //         console.log('New MCQS:', newMCQS)
  //         setFilteredQuestion(newMCQS)
  //       }

  //       if (result.success) {
  //         setIsLoading(false)

  //         if (showFilteredResult) {
  //           setFilteredQuestion(
  //             filteredQuestion.map((ques) => {
  //               if (ques.srNo === srNo - 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         } else {
  //           setAllQuestion(
  //             allQuestion.map((ques) => {
  //               if (ques.srNo === srNo - 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         }
  //       }
  //     })

  //     .catch((error) => console.log('error', error))
  //   setIsLoading(false)
  // }

  const moveUpHandler = (srNo, usmleStep, USMLE) => {
    setErrorMsg('')
    setIsLoading(true)

    const targetSrNo = srNo - 1

    // Swap function that finds and swaps the questions based on srNo
    const swapQuestions = (questions, targetSrNo, srNo) => {
      const updatedQuestions = [...questions]

      // Find the index of the current and target questions
      const targetQuestionIndex = updatedQuestions.findIndex((ques) => ques.srNo === targetSrNo)
      const currentQuestionIndex = updatedQuestions.findIndex((ques) => ques.srNo === srNo)

      // If both questions are found, swap them
      if (targetQuestionIndex !== -1 && currentQuestionIndex !== -1) {
        const temp = updatedQuestions[targetQuestionIndex]
        updatedQuestions[targetQuestionIndex] = updatedQuestions[currentQuestionIndex]
        updatedQuestions[currentQuestionIndex] = temp

        // Update the srNo for the swapped questions
        updatedQuestions[targetQuestionIndex].srNo = targetSrNo
        updatedQuestions[currentQuestionIndex].srNo = srNo
      }

      return updatedQuestions
    }

    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        srNo: srNo,
        usmleStep: usmleStep,
        USMLE: USMLE,
        newSrNo: targetSrNo, // Decrement srNo
      }),
      redirect: 'follow',
    }

    console.log('+++++++++++++++++++++++++++')
    console.log('Moving up question:', srNo)

    // Swap the questions locally before making the API call
    let updatedQuestions
    if (showFilteredResult) {
      updatedQuestions = swapQuestions(filteredQuestion, targetSrNo, srNo)
      setFilteredQuestion(updatedQuestions) // Set the filteredQuestion with the swapped list
    } else {
      updatedQuestions = swapQuestions(allQuestion, targetSrNo, srNo)
      setAllQuestion(updatedQuestions) // Set the allQuestion with the swapped list
    }

    // API call to update the question list on the backend
    fetch(API_URL + 'edit-sr-no-for-question', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('API result:', result)

        if (result.success) {
          setIsLoading(false)

          // If the API returns new questions, update the state with them
          if (result.newQuestions) {
            const newMCQS = result.newQuestions
            console.log('New MCQS:', newMCQS)

            // Update the filteredQuestion with the new questions from the API
            setFilteredQuestion(newMCQS) // Assuming the result has a property newQuestions
          }
        } else {
          console.error('API error:', result.message)
        }
      })
      .catch((error) => {
        console.log('Error:', error)
        setIsLoading(false)
      })

    setIsLoading(false)
  }

  // const moveDownHandler = () => {
  //   const myHeaders = new Headers()
  //   myHeaders.append('Authorization', token)
  //   myHeaders.append('Content-Type', 'application/json')

  //   const newSRNO = oldSrNo + 1
  //   console.log(oldSrNo, newSRNO)
  //   const requestOptions = {
  //     method: 'PUT',
  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       srNo: oldSrNo,
  //       usmleStep: usmleStep,
  //       USMLE: USMLE,
  //       newSrNo: newSRNO,
  //     }),
  //     redirect: 'follow',
  //   }
  //   console.log('+++++++++++++++++++++++++++')
  //   console.log(oldSrNo)
  //   fetch(API_URL + 'edit-sr-no-for-question', requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log('result', result)
  //       // Handle success

  //       if (result.newQuestions) {
  //         const newMCQS = result.newQuestions
  //         console.log('New MCQS:', newMCQS)
  //         setFilteredQuestion(newMCQS)
  //       }
  //       if (result.success) {
  //         setOldSrNo('')
  //         setUsmleStep('')
  //         setUSMLE('')
  //         if (showFilteredResult) {
  //           setFilteredQuestion(
  //             filteredQuestion.map((ques) => {
  //               if (ques.srNo === srNo - 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         } else {
  //           setAllQuestion(
  //             allQuestion.map((ques) => {
  //               if (ques.srNo === srNo - 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         }
  //       }
  //     })
  //     .catch((error) => console.log('error', error))
  //   // setOldSrNo('')
  //   // setUsmleStep('')
  //   // setUSMLE('')
  // }

  // const moveDownHandler = (srNo, usmleStep, USMLE) => {
  //   setIsLoading(true)

  //   const myHeaders = new Headers()
  //   myHeaders.append('Authorization', token)
  //   myHeaders.append('Content-Type', 'application/json')

  //   const newSRNO = srNo + 1
  //   console.log(srNo, newSRNO)

  //   const requestOptions = {
  //     method: 'PUT',
  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       srNo: srNo,
  //       usmleStep: usmleStep,
  //       USMLE: USMLE,
  //       newSrNo: newSRNO,
  //     }),
  //     redirect: 'follow',
  //   }

  //   console.log('+++++++++++++++++++++++++++')
  //   console.log('Moving down question:', srNo)

  //   fetch(API_URL + 'edit-sr-no-for-question', requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log('result', result)

  //       // Update questions list with new MCQs
  //       if (result.newQuestions) {
  //         const newMCQS = result.newQuestions
  //         console.log('New MCQS:', newMCQS)
  //         setFilteredQuestion(newMCQS)
  //       }

  //       if (result.success) {
  //         setIsLoading(false)

  //         if (showFilteredResult) {
  //           setFilteredQuestion(
  //             filteredQuestion.map((ques) => {
  //               if (ques.srNo === srNo + 1) {
  //                 return { ...ques, srNo: srNo }
  //               }
  //               return ques
  //             }),
  //           )
  //         }
  //       }
  //     })
  //     .catch((error) => console.log('error', error))
  //   setIsLoading(false)
  // }

  const moveDownHandler = (srNo, usmleStep, USMLE) => {
    setIsLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Authorization', token)
    myHeaders.append('Content-Type', 'application/json')

    const newSRNO = srNo + 1
    console.log('Moving down question:', srNo, 'to', newSRNO)

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        srNo: srNo,
        usmleStep: usmleStep,
        USMLE: USMLE,
        newSrNo: newSRNO, // Increment srNo
      }),
      redirect: 'follow',
    }

    // Function to swap the questions in the list
    const swapQuestions = (questions, srNo, newSRNO) => {
      const updatedQuestions = [...questions]

      // Find the index of the current and target questions
      const currentQuestionIndex = updatedQuestions.findIndex((ques) => ques.srNo === srNo)
      const targetQuestionIndex = updatedQuestions.findIndex((ques) => ques.srNo === newSRNO)

      // If both questions are found, swap them
      if (currentQuestionIndex !== -1 && targetQuestionIndex !== -1) {
        const temp = updatedQuestions[currentQuestionIndex]
        updatedQuestions[currentQuestionIndex] = updatedQuestions[targetQuestionIndex]
        updatedQuestions[targetQuestionIndex] = temp

        // Update the srNo for the swapped questions
        updatedQuestions[currentQuestionIndex].srNo = srNo
        updatedQuestions[targetQuestionIndex].srNo = newSRNO
      }

      return updatedQuestions
    }

    console.log('+++++++++++++++++++++++++++')
    console.log('Moving down question:', srNo)

    // Swap the questions in local state before making the API call
    let updatedQuestions
    if (showFilteredResult) {
      updatedQuestions = swapQuestions(filteredQuestion, srNo, newSRNO)
      setFilteredQuestion(updatedQuestions) // Update filteredQuestion with the swapped list
    } else {
      updatedQuestions = swapQuestions(allQuestion, srNo, newSRNO)
      setAllQuestion(updatedQuestions) // Update allQuestion with the swapped list
    }

    // API call to update the question list on the backend
    fetch(API_URL + 'edit-sr-no-for-question', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('API result:', result)

        if (result.success) {
          setIsLoading(false)

          // If the API returns new questions, update the state with them
          if (result.newQuestions) {
            const newMCQS = result.newQuestions
            console.log('New MCQS:', newMCQS)

            // Update the filteredQuestion with the new questions from the API
            setFilteredQuestion(newMCQS) // Assuming the result has a property newQuestions
          }
        } else {
          console.error('API error:', result.message)
        }
      })
      .catch((error) => {
        console.log('Error:', error)
        setIsLoading(false)
      })

    setIsLoading(false)
  }

  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [firstTargetSrNo, setFirstTargetSrNo] = useState('')
  // const [usmleStep, setUsmleStep] = useState("");
  // const [USMLE, setUSMLE] = useState("");
  const [message, setMessage] = useState('')
  const [targetSrNos, setTargetSrNos] = useState([])

  const handleMoveCheckboxChange = (srNo) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.includes(srNo)
        ? prevSelected.filter((item) => item !== srNo)
        : [...prevSelected, srNo],
    )
  }

  const handleSubmitMove = async (e) => {
    e.preventDefault()
    setErrorMsg('')
    setIsLoading(true)

    if (selectedQuestions.length === 0) {
      setErrorMsg('Please select at least one question to move.')
      setTimeout(() => {
        setSuccess(false)
        setErrorMsg('')
        // navigate('/manage-quiz')
      }, 3000)
      return
    }

    const data = {
      srNos: selectedQuestions,
      firstTargetSrNo: parseInt(firstTargetSrNo),
      usmleStep: filterUsmle,
      USMLE: filterCategory,
    }

    try {
      const response = await fetch(API_URL + 'move-bulk-questions', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        // setSuccessMsg(response.message)
        setIsLoading(false)

        setErrorMsg(response.message)
        setTimeout(() => {
          setSuccess(false)
          setErrorMsg('')
          // navigate('/manage-quiz')
        }, 3000)
        throw new Error('Failed to Sort Questions')
      }

      const responseData = await response.json()

      // const newMCQS = responseData.newMCQS
      // console.log('New MCQs:', newMCQS)
      // setSuccessMsg(responseData.message)
      // setTimeout(() => {
      //   setSuccess(true)
      //   setSuccessMsg('')
      //   // navigate('/manage-quiz')
      // }, 3000)
      setIsLoading(false)

      setTargetSrNos(responseData.targetSrNos)
      setFirstTargetSrNo('')
      setShowMoveCheck(false)
      getFilteredQuestions(pageNumberForLater)
      setSelectedQuestions([])
    } catch (error) {
      setMessage(error.message || 'Error occurred while swapping SR numbers')
      setIsLoading(false)
    }
  }

  // const [filteredQuestion, setFilteredQuestion] = useState([])

  return (
    <AdminLayout>
      <>
        <CCard className="mb-4 mx-4">
          <CCardHeader>
            <div className="flex flex-col mb-2">
              <strong>Manage Questions</strong>
              {!loader &&
                allQuestion.length > 0 &&
                (showFilteredResult ? (
                  <span className="text-sm">Total {filtertotal} questions found</span>
                ) : (
                  <span className="text-sm">Total {total + 2000} questions added</span>
                ))}
            </div>
            <div className="flex justify-between items-center">
              <div className="flex justify-between items-center">
                <CFormSelect
                  aria-label="usmle step"
                  id="usmleStep"
                  options={[
                    { label: 'USMLE Step', value: '' },
                    { label: 'Step 1', value: '1' },
                    { label: 'Step 2', value: '2' },
                    { label: 'Step 3', value: '3' },
                  ]}
                  value={filterUsmle}
                  onChange={(e) => {
                    setFilterUsmle(e.target.value)
                    setFilterCategory('')
                  }}
                  className="mr-3 w-full"
                />
                <CFormSelect
                  aria-label="usmle category"
                  id="usmleCategory"
                  defaultValue={getValues('usmleCategory')}
                  className="mr-3 w-full"
                  // options={
                  //   filterUsmle == '1'
                  //     ? [
                  //         { label: 'USMLE Category', value: '' },
                  //         { label: 'Microbiology', value: 'Microbiology' },
                  //         { label: 'Immunology', value: 'Immunology' },
                  //         { label: 'Histology', value: 'Histology' },
                  //         { label: 'Anatomy', value: 'Anatomy' },
                  //         { label: 'Physiology', value: 'Physiology' },
                  //         { label: 'Embryology', value: 'Embryology' },
                  //         { label: 'Biochemistry', value: 'Biochemistry' },
                  //       ]
                  //     : filterUsmle == '2'
                  //       ? [
                  //           { label: 'USMLE Category', value: '' },
                  //           { label: 'Internal Medicine', value: 'Internal Medicine' },
                  //           { label: 'Surgery', value: 'Surgery' },
                  //           { label: 'Pediatrics', value: 'Pediatrics' },
                  //           {
                  //             label: 'Obstetrics and Gynecology',
                  //             value: 'Obstetrics and Gynecology',
                  //           },
                  //           { label: 'Psychiatry', value: 'Psychiatry' },
                  //           { label: 'Preventive Medicine', value: 'Preventive Medicine' },
                  //           { label: 'Family Medicine', value: 'Family Medicine' },
                  //         ]
                  //       : filterUsmle == '3'
                  //         ? [
                  //             { label: 'USMLE Category', value: '' },
                  //             { label: 'Internal Medicine', value: 'Internal Medicine' },
                  //             { label: 'Surgery', value: 'Surgery' },
                  //             { label: 'Pediatrics', value: 'Pediatrics' },
                  //             {
                  //               label: 'Obstetrics and Gynecology',
                  //               value: 'Obstetrics and Gynecology',
                  //             },
                  //             { label: 'Psychiatry', value: 'Psychiatry' },
                  //             { label: 'Preventive Medicine', value: 'Preventive Medicine' },
                  //             { label: 'Family Medicine', value: 'Family Medicine' },
                  //           ]
                  //         : [{ label: 'USMLE Category', value: '' }]
                  // }
                  value={filterCategory}
                  onChange={(e) => setFilterCategory(e.target.value)}
                >
                  <option>USMLE Category</option>
                  {filterUsmle == '1' ? (
                    step1Categories.map((category, idx) => (
                      <option key={idx} value={category}>
                        {category}
                      </option>
                    ))
                  ) : filterUsmle == '2' ? (
                    step2Categories.map((category, idx) => (
                      <option key={idx} value={category}>
                        {category}
                      </option>
                    ))
                  ) : filterUsmle == '3' ? (
                    step3Categories.map((category, idx) => (
                      <option key={idx} value={category}>
                        {category}
                      </option>
                    ))
                  ) : (
                    <option>Select USMLE Category</option>
                  )}
                </CFormSelect>
                <CButton
                  // disabled={filterUsmle || filterCategory ? false : true}
                  className="text-white bg-[#6261CC]  hover:bg-[#4f4ea0] flex justify-center items-center"
                  onClick={() => {
                    setfilterCurrentPage(1)
                    getFilteredQuestions(1)
                  }}
                >
                  <CIcon icon={cilFilter} className="mr-1 mt-1" /> Filter
                </CButton>
                {filterUsmle || filterCategory ? (
                  <CButton
                    className="text-white bg-[#6261CC]  hover:bg-[#4f4ea0] ml-3 flex"
                    onClick={() => {
                      setFilterUsmle('')
                      setFilterCategory('')
                      setShowFilteredResult(false)
                    }}
                  >
                    Clear
                  </CButton>
                ) : (
                  ''
                )}
              </div>
              {total > pageSize && !showFilteredResult && !loader && (
                <ReactPaginate
                  breakLabel={
                    <span className="w-9 h-9 border border-solid">
                      <span className="flex justify-center items-center">...</span>
                    </span>
                  }
                  marginPagesDisplayed={1}
                  nextLabel={
                    <span
                      className={`${showNextButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-r-md`}
                    >
                      <RiArrowRightSLine />
                    </span>
                  }
                  // onPageChange={handlePageClick}
                  onPageChange={(event) => {
                    setCurrentPage(event.selected + 1)
                    getAllQuest(event.selected + 1)
                  }}
                  pageRangeDisplayed={1}
                  pageCount={totalPages}
                  previousLabel={
                    <span
                      className={`${showPrevButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-l-md`}
                    >
                      <RiArrowLeftSLine />
                    </span>
                  }
                  containerClassName={'flex justify-center items-center pagination mr-3'}
                  pageClassName={
                    'block border border-solid page-no border-lightGray hover:bg-lightGray w-9 h-9 flex justify-center items-center '
                  }
                  activeClassName={'active-page-no text-white'}
                  forcePage={currentPage - 1}
                  // initialPage={currentPage}
                />
              )}
              {filtertotal > filterpageSize && showFilteredResult && (
                <ReactPaginate
                  breakLabel={
                    <span className="w-9 h-9 border border-solid">
                      <span className="flex justify-center items-center">...</span>
                    </span>
                  }
                  marginPagesDisplayed={1}
                  nextLabel={
                    <span
                      className={`${showFilterNextButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-r-md`}
                    >
                      <RiArrowRightSLine />
                    </span>
                  }
                  // onPageChange={handlePageClick}
                  onPageChange={(event) => {
                    setfilterCurrentPage(event.selected + 1)
                    getFilteredQuestions(event.selected + 1)
                  }}
                  pageRangeDisplayed={1}
                  pageCount={filtertotalPages}
                  previousLabel={
                    <span
                      className={`${showFilterPrevButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-l-md`}
                    >
                      <RiArrowLeftSLine />
                    </span>
                  }
                  containerClassName={'flex justify-center items-center pagination mr-3'}
                  pageClassName={
                    'block border border-solid page-no border-lightGray hover:bg-lightGray w-9 h-9 flex justify-center items-center '
                  }
                  activeClassName={'active-page-no text-white'}
                  forcePage={filtercurrentPage - 1}
                  // initialPage={currentPage}
                />
              )}
              <div className="flex justify-end items-center w-[505px]">
                <div>
                  {!showCheck && showFilteredResult && !showMoveCheck && (
                    <CButton
                      className="text-white bg-[#6261CC]  hover:bg-[#4f4ea0] mr-3  whitespace-nowrap"
                      onClick={toggleCheckBox}
                    >
                      Sort
                    </CButton>
                  )}
                  {showMoveCheck && (
                    <div className="flex">
                      <input
                        type="number"
                        placeholder="Enter Number"
                        value={firstTargetSrNo}
                        onChange={(e) => setFirstTargetSrNo(e.target.value)}
                        className="bg-white rounded-md dark:text-black p-2 appearance-none border "
                        required
                      />
                      <CButton
                        className="text-white bg-[#6261CC]  hover:bg-[#4f4ea0] mx-3  whitespace-nowrap"
                        onClick={handleSubmitMove}
                      >
                        Move
                      </CButton>
                      <CButton
                        className="text-white bg-red-400  hover:bg-red-600 mr-3  whitespace-nowrap"
                        onClick={cancelMoveCheck}
                      >
                        Cancel Sort
                      </CButton>
                    </div>
                  )}
                </div>
                <div>
                  {!showCheck && !showMoveCheck && (
                    <div className="flex w-full">
                      <CButton
                        className="text-white bg-[#6261CC]  hover:bg-[#4f4ea0] mr-3  whitespace-nowrap"
                        onClick={() => {
                          setShowCheck(true)
                        }}
                      >
                        Select to Delete
                      </CButton>
                    </div>
                  )}
                </div>
                <div>
                  <div className="flex gap-3">
                    {showCheck && !showMoveCheck && (
                      <CButton
                        className="text-white bg-gray-600 hover:bg-gray-700"
                        onClick={() => {
                          setErrorr(false)
                          setErrorMsg('')
                          setShowCheck(false)
                          setDeleteIds([])
                        }}
                      >
                        Cancel
                      </CButton>
                    )}
                    {deleteIds && deleteIds.length > 0 && (
                      <>
                        <CButton
                          className="text-white bg-red-600 hover:bg-red-700 mr-3"
                          onClick={() => {
                            setBulkDeleteModal(true)
                            setErrorr(false)
                            setErrorMsg('')
                          }}
                        >
                          Delete Questions
                        </CButton>
                      </>
                    )}
                  </div>
                </div>
                {!showCheck && !showMoveCheck && (
                  <CButton
                    className="text-white bg-[#6261CC]  hover:bg-[#4f4ea0] whitespace-nowrap"
                    onClick={() => {
                      setAddModal(true)
                      setIsLoading(false)
                      reset({})
                      setQuestionId('')
                      setErrorr(false)
                      setErrorMsg('')
                    }}
                  >
                    Add Question
                  </CButton>
                )}
              </div>
            </div>
          </CCardHeader>
          {success && (
            <CAlert color="success" className="success-alert">
              {successMsg}
            </CAlert>
          )}
          {errorMsg && (
            <CAlert color="danger" className="error-alert">
              {errorMsg}
            </CAlert>
          )}
          <CCardBody>
            {/* {loader ? (
              <div className="text-center">
                <CSpinner className="bg-[#6261CC]" variant="grow" />
              </div>
            ) : ( */}
            <CTable striped className="admin-tables">
              <CTableHead>
                <CTableRow>
                  {showCheck && (
                    <CTableHeaderCell scope="col">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        // id={q._id}
                        // value={q._id}
                        onChange={(e) => handleCheckboxChangeAll(e)}
                      />
                    </CTableHeaderCell>
                  )}
                  {showMoveCheck && (
                    <CTableDataCell>
                      <div></div>
                    </CTableDataCell>
                  )}
                  <CTableHeaderCell>Sr No</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Question</CTableHeaderCell>
                  <CTableHeaderCell scope="col">USMLE Step</CTableHeaderCell>
                  <CTableHeaderCell scope="col">USMLE Category</CTableHeaderCell>
                  {/* <CTableHeaderCell scope="col">Image</CTableHeaderCell> */}
                  <CTableHeaderCell scope="col">Correct Answer</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {loader ? (
                  <CTableRow>
                    <CTableDataCell className="text-center" colSpan={6}>
                      <div className="lds-spinner mt-1 mb-4">
                        {[...Array(12)].map((_, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ) : (
                  <>
                    {allQuestion && allQuestion.length > 0 ? (
                      showFilteredResult ? (
                        filteredQuestion && filteredQuestion.length > 0 ? (
                          filteredQuestion
                            .sort((a, b) => (a.srNo || 0) - (b.srNo || 0))
                            .map((q, idx) => (
                              <CTableRow key={idx}>
                                {showCheck && (
                                  <CTableDataCell>
                                    <input
                                      type="checkbox"
                                      className="form-check-input checkboxes"
                                      id={q._id}
                                      value={q._id}
                                      onChange={(e) => handleCheckboxChange(e)}
                                    />
                                  </CTableDataCell>
                                )}
                                {showMoveCheck && (
                                  <CTableDataCell>
                                    <input
                                      type="checkbox"
                                      className="form-check-input checkboxes"
                                      id={q._id}
                                      checked={selectedQuestions.includes(q.srNo)}
                                      onChange={() => handleMoveCheckboxChange(q.srNo)}
                                    />
                                  </CTableDataCell>
                                )}
                                <CTableHeaderCell>
                                  <div className={q.isCorrect ? 'text-green-600' : ''}>
                                    {q.srNo}
                                  </div>
                                </CTableHeaderCell>
                                <CTableDataCell className="cursor-pointer">
                                  <span
                                    className="whitespace-nowrap"
                                    id={q._id}
                                    onClick={(e) => {
                                      setDetailModal(true)
                                      setQuestionId(e.currentTarget.id)
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        q.question.length > 60
                                          ? q.question.substring(0, 60) + '...'
                                          : q.question,
                                    }}
                                  >
                                    {/* {q.question.length > 100
                                ? q.question.substring(0, 100) + '...'
                                : q.question} */}
                                  </span>
                                </CTableDataCell>
                                <CTableDataCell>{q.usmleStep}</CTableDataCell>
                                <CTableDataCell>{q.USMLE}</CTableDataCell>
                                {/* <CTableDataCell>
                            <img
                              src={`${API_URL}uploads/${q.image}`}
                              alt="mcq img"
                              className="w-6 h-6 rounded-full"
                            />
                          </CTableDataCell> */}
                                <CTableDataCell>
                                  <span
                                    className="whitespace-nowrap"
                                    id={q._id}
                                    onClick={(e) => {
                                      setDetailModal(true)
                                      setQuestionId(e.currentTarget.id)
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        q.correctAnswer.length > 30
                                          ? q.correctAnswer.substring(0, 30) + '...'
                                          : q.correctAnswer,
                                    }}
                                  ></span>
                                </CTableDataCell>
                                <CTableDataCell className="flex justify-between items-center">
                                  <div>
                                    <CTooltip content="View Question">
                                      <CButton
                                        className="text-white bg-[#6261CC] hover:bg-[#4f4ea0] mr-1 my-1 px-2 py-1.5 text-md"
                                        id={q._id}
                                        onClick={(e) => {
                                          setViewModal(true)
                                          setQuestionId(e.currentTarget.id)
                                        }}
                                        title="View"
                                      >
                                        <RiEyeLine className="text-md" />
                                      </CButton>
                                    </CTooltip>
                                    <CTooltip
                                      key={q._id}
                                      content={q.isCorrect ? 'Uncheck Question' : 'Check Question'}
                                    >
                                      <CButton
                                        className={
                                          q.isCorrect
                                            ? 'text-white bg-green-800 hover:bg-green-600 mr-1 my-1 px-2 py-2 text-sm'
                                            : 'text-white bg-green-600 hover:bg-green-800 mr-1 my-1 px-2 py-2 text-sm'
                                        }
                                        id={q._id}
                                        onClick={(e) => {
                                          if (q.isCorrect) {
                                            setQuestionId(e.currentTarget.id)
                                            unMarkCorrect(e.currentTarget.id)
                                          } else {
                                            setQuestionId(e.currentTarget.id)
                                            markCorrect(e.currentTarget.id)
                                          }
                                        }}
                                      >
                                        {q.isCorrect ? (
                                          <ImCross className="text-xs" />
                                        ) : (
                                          <FaCheck className="text-xs" />
                                        )}
                                      </CButton>
                                    </CTooltip>
                                    <CTooltip content="Edit Question">
                                      <CButton
                                        color="info"
                                        className="text-white mr-1 my-1 px-2 py-1 text-sm"
                                        id={q._id}
                                        onClick={(e) => {
                                          setAddModal(true)
                                          setQuestionId(e.currentTarget.id)
                                          setErrorr(false)
                                          setErrorMsg('')
                                          setSrNo(q.srNo)
                                        }}
                                      >
                                        <CIcon icon={cilPencil} />
                                      </CButton>
                                    </CTooltip>
                                    <CTooltip content="Delete Question">
                                      <CButton
                                        color="danger"
                                        className="text-white my-1 px-2 py-1 text-sm"
                                        id={q._id}
                                        onClick={(e) => {
                                          setDeleteModal(true)
                                          setQuestionId(e.currentTarget.id)
                                          setErrorr(false)
                                          setErrorMsg('')
                                        }}
                                      >
                                        <CIcon icon={cilTrash} />
                                      </CButton>
                                    </CTooltip>
                                  </div>
                                  {/* <div>
                                    <CButton
                                      className="text-[#4F4EA0] ml-5 my-1 hover:bg-[#4f4ea065] hover:text-[white] px-2 py-2 text-sm"
                                      id={q._id}
                                      onClick={(e) => {
                                        setDeleteModal(true)
                                        setQuestionId(e.currentTarget.id)
                                        setErrorr(false)
                                        setErrorMsg('')
                                      }}
                                    >
                                      <BiDotsVertical className=" rotate-180" />
                                    </CButton>

                                    <CButton
                                      className="text-[#4F4EA0] ml-5 my-1 hover:bg-[#4f4ea065] hover:text-[white] px-2 py-2 text-sm"
                                      id={q._id}
                                      onClick={(e) => {
                                        setDeleteModal(true)
                                        setQuestionId(e.currentTarget.id)
                                        setErrorr(false)
                                        setErrorMsg('')
                                      }}
                                    >
                                      Move Down
                                    </CButton>

                                    <CButton
                                      className="text-[#4F4EA0] my-1 ml-1 px-2 py-2 text-sm hover:bg-[#4f4ea065] hover:text-[white]"
                                      id={q._id}
                                      onClick={(e) => {
                                        setOldSrNo(q.srNo)
                                        setUsmleStep(q.usmleStep)
                                        setUSMLE(q.USMLE)
                                        moveUpHandler()
                                      }}
                                    >
                                      Move Up
                                    </CButton>
                                  </div> */}
                                  <div className="relative">
                                    {/* 3-Dot Button */}
                                    <CButton
                                      className="text-[#4F4EA0] ml-5 my-1 hover:bg-[#4f4ea065] hover:text-[white] px-2 py-2 text-sm"
                                      id={q._id}
                                      onClick={() => toggleDropdown(q._id)}
                                    >
                                      <BiDotsVertical className="rotate-180" />
                                    </CButton>

                                    {/* Dropdown Menu */}
                                    {openDropdownId === q._id && (
                                      <div
                                        ref={dropdownRef} // Attach ref to dropdown
                                        className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50"
                                      >
                                        {/* <button
                                          className="block w-full text-left px-4 py-2 text-sm text-[#4F4EA0] hover:bg-[#4f4ea065] hover:text-white"
                                          onClick={() => {
                                            setOldSrNo(q.srNo)
                                            setUsmleStep(q.usmleStep)
                                            setUSMLE(q.USMLE)
                                            moveUpHandler()
                                            setOpenDropdownId(null)
                                          }}
                                        >
                                          Move Up
                                        </button> */}
                                        <button
                                          className="block w-full text-left px-4 py-2 text-sm text-[#4F4EA0] hover:bg-[#4f4ea065] hover:text-white"
                                          onClick={() => {
                                            moveUpHandler(q.srNo, q.usmleStep, q.USMLE)
                                            setOpenDropdownId(null)
                                          }}
                                        >
                                          Move Up
                                        </button>
                                        {/* <button
                                          className="block w-full text-left px-4 py-2 text-sm text-[#4F4EA0] hover:bg-[#4f4ea065] hover:text-white"
                                          onClick={() => {
                                            setOldSrNo(q.srNo)
                                            setUsmleStep(q.usmleStep)
                                            setUSMLE(q.USMLE)
                                            moveDownHandler()
                                            setOpenDropdownId(null)
                                          }}
                                        >
                                          Move Down
                                        </button> */}

                                        <button
                                          className="block w-full text-left px-4 py-2 text-sm text-[#4F4EA0] hover:bg-[#4f4ea065] hover:text-white"
                                          onClick={() => {
                                            moveDownHandler(q.srNo, q.usmleStep, q.USMLE)
                                            setOpenDropdownId(null)
                                          }}
                                        >
                                          Move Down
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </CTableDataCell>
                              </CTableRow>
                            ))
                        ) : (
                          <CTableRow>
                            <CTableDataCell className="text-center" colSpan={6}>
                              No records found <br />
                              <CButton color="link" onClick={() => setShowFilteredResult(false)}>
                                Show All
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      ) : (
                        allQuestion.map((q, idx) => (
                          <CTableRow key={idx}>
                            {showCheck && (
                              <CTableDataCell>
                                <input
                                  type="checkbox"
                                  className="form-check-input checkboxes"
                                  id={q._id}
                                  value={q._id}
                                  onChange={(e) => handleCheckboxChange(e)}
                                />
                              </CTableDataCell>
                            )}
                            <CTableHeaderCell>
                              <div className={q.isCorrect ? 'text-green-600' : ''}>{q.srNo}</div>
                            </CTableHeaderCell>
                            <CTableDataCell className="cursor-pointer">
                              <span
                                id={q._id}
                                onClick={(e) => {
                                  setDetailModal(true)
                                  setQuestionId(e.currentTarget.id)
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    q.question.length > 100
                                      ? q.question.substring(0, 100) + '...'
                                      : q.question,
                                }}
                              />
                            </CTableDataCell>
                            <CTableDataCell>{q.usmleStep}</CTableDataCell>
                            <CTableDataCell>{q.USMLE}</CTableDataCell>
                            <CTableDataCell>{q.correctAnswer}</CTableDataCell>
                            <CTableDataCell className="flex justify-start items-center">
                              <CTooltip content="View Question">
                                <CButton
                                  className="text-white bg-[#6261CC] hover:bg-[#4f4ea0] mr-3 my-2"
                                  id={q._id}
                                  onClick={(e) => {
                                    setViewModal(true)
                                    setQuestionId(e.currentTarget.id)
                                  }}
                                  title="View"
                                >
                                  <RiEyeLine className="my-1" />
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                key={q._id}
                                content={q.isCorrect ? 'Uncheck Question' : 'Check Question'}
                              >
                                <CButton
                                  className={
                                    q.isCorrect
                                      ? 'text-white bg-green-800 hover:bg-green-600 mr-3 my-2'
                                      : 'text-white bg-green-600 hover:bg-green-800 mr-3 my-2'
                                  }
                                  id={q._id}
                                  onClick={(e) => {
                                    if (q.isCorrect) {
                                      setQuestionId(e.currentTarget.id)
                                      unMarkCorrect(e.currentTarget.id)
                                    } else {
                                      setQuestionId(e.currentTarget.id)
                                      markCorrect(e.currentTarget.id)
                                    }
                                  }}
                                >
                                  {q.isCorrect ? (
                                    <ImCross className="my-1" />
                                  ) : (
                                    <FaCheck className="my-1" />
                                  )}
                                </CButton>
                              </CTooltip>
                              <CTooltip content="View Question">
                                <CButton
                                  color="info"
                                  className="text-white mr-3 my-2"
                                  id={q._id}
                                  onClick={(e) => {
                                    setAddModal(true)
                                    setQuestionId(e.currentTarget.id)
                                    setErrorr(false)
                                    setErrorMsg('')
                                    setSrNo(q.srNo)
                                  }}
                                >
                                  <CIcon icon={cilPencil} />
                                </CButton>
                              </CTooltip>
                              <CTooltip content="Delete Question">
                                <CButton
                                  color="danger"
                                  className="text-white my-2"
                                  id={q._id}
                                  onClick={(e) => {
                                    setDeleteModal(true)
                                    setQuestionId(e.currentTarget.id)
                                    setErrorr(false)
                                    setErrorMsg('')
                                  }}
                                >
                                  <CIcon icon={cilTrash} />
                                </CButton>
                              </CTooltip>
                            </CTableDataCell>
                          </CTableRow>
                        ))
                      )
                    ) : (
                      <CTableRow>
                        <CTableDataCell className="text-center" colSpan={6}>
                          No Questions Found
                        </CTableDataCell>
                      </CTableRow>
                    )}
                    {total > pageSize && !showFilteredResult && (
                      <CTableRow>
                        <CTableDataCell className="text-center" colSpan={6}>
                          <ReactPaginate
                            breakLabel={
                              <span className="w-9 h-9 border border-solid">
                                <span className="flex justify-center items-center">...</span>
                              </span>
                            }
                            marginPagesDisplayed={1}
                            nextLabel={
                              <span
                                className={`${showNextButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-r-md`}
                              >
                                <RiArrowRightSLine />
                              </span>
                            }
                            // onPageChange={handlePageClick}
                            onPageChange={(event) => {
                              setCurrentPage(event.selected + 1)
                              getAllQuest(event.selected + 1)
                            }}
                            pageRangeDisplayed={1}
                            pageCount={totalPages}
                            previousLabel={
                              <span
                                className={`${showPrevButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-l-md`}
                              >
                                <RiArrowLeftSLine />
                              </span>
                            }
                            containerClassName={'flex justify-center items-center pagination mr-3'}
                            pageClassName={
                              'block border border-solid page-no border-lightGray hover:bg-lightGray w-9 h-9 flex justify-center items-center '
                            }
                            activeClassName={'active-page-no text-white'}
                            forcePage={currentPage - 1}
                            // initialPage={currentPage}
                          />
                        </CTableDataCell>
                      </CTableRow>
                    )}
                    {filtertotal > filterpageSize && showFilteredResult && (
                      <CTableRow className="my-2">
                        <CTableDataCell className="text-center" colSpan={6}>
                          <ReactPaginate
                            breakLabel={
                              <span className="w-9 h-9 border border-solid">
                                <span className="flex justify-center items-center">...</span>
                              </span>
                            }
                            marginPagesDisplayed={1}
                            nextLabel={
                              <span
                                className={`${showFilterNextButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-r-md`}
                              >
                                <RiArrowRightSLine />
                              </span>
                            }
                            // onPageChange={handlePageClick}
                            onPageChange={(event) => {
                              setfilterCurrentPage(event.selected + 1)
                              getFilteredQuestions(event.selected + 1)
                            }}
                            pageRangeDisplayed={1}
                            pageCount={filtertotalPages}
                            previousLabel={
                              <span
                                className={`${showFilterPrevButton ? 'page-no cursor-pointer' : 'cursor-disabled opacity-50'} w-9 h-9 flex justify-center items-center border border-solid rounded-l-md`}
                              >
                                <RiArrowLeftSLine />
                              </span>
                            }
                            containerClassName={'flex justify-center items-center pagination mr-3'}
                            pageClassName={
                              'block border border-solid page-no border-lightGray hover:bg-lightGray w-9 h-9 flex justify-center items-center '
                            }
                            activeClassName={'active-page-no text-white'}
                            forcePage={filtercurrentPage - 1}
                            // initialPage={currentPage}
                          />
                        </CTableDataCell>
                      </CTableRow>
                    )}
                  </>
                )}
              </CTableBody>
            </CTable>
            {/* )} */}
          </CCardBody>
        </CCard>
        {/* add / edit modal */}
        <CModal
          alignment="center"
          visible={addModal}
          onClose={() => {
            setAddModal(false)
            setQuestionId('')
          }}
          aria-labelledby="VerticallyCenteredExample"
          // scrollable={true}
          size="lg"
          backdrop="static"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">
              {questionId ? 'Edit' : 'Add'} Question
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={questionId ? handleSubmit(editQuestion) : handleSubmit(addQuestion)}>
            <CModalBody>
              <CForm>
                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormSelect
                      label="USMLE Step"
                      aria-label="usmle step"
                      id="usmleStep"
                      options={[
                        { label: 'Select USMLE Step', value: '' },
                        { label: 'Step 1', value: '1' },
                        { label: 'Step 2', value: '2' },
                        { label: 'Step 3', value: '3' },
                      ]}
                      {...register('usmleStep', { required: true })}
                      feedback="Please select USMLE Step"
                      invalid={errors.usmleStep ? true : false}
                      defaultValue={getValues('usmleStep')}
                      // onChange={(e) => setUsmleStep(e.target.value)}
                    />
                  </CCol>
                </CRow>
                {Number(getValues('usmleStep')) > 0 ? (
                  <CRow className="mb-3">
                    <CCol md={12}>
                      {getValues('usmleStep') == '1' ? (
                        <CFormSelect
                          label="USMLE Category"
                          aria-label="usmle category"
                          id="usmleCategory"
                          defaultValue={getValues('usmleCategory')}
                          // options={[
                          //   { label: 'Select USMLE Category', value: '' },
                          //   { label: 'Microbiology', value: 'Microbiology' },
                          //   { label: 'Immunology', value: 'Immunology' },
                          //   { label: 'Histology', value: 'Histology' },
                          //   { label: 'Anatomy', value: 'Anatomy' },
                          //   { label: 'Physiology', value: 'Physiology' },
                          //   { label: 'Embryology', value: 'Embryology' },
                          //   { label: 'Biochemistry', value: 'Biochemistry' },
                          // ]}
                          {...register('usmleCategory', { required: true })}
                          feedback="Please select USMLE Category."
                          invalid={errors.usmleCategory ? true : false}
                          // onChange={(e) => setUsmleCategory(e.target.value)}
                        >
                          <option>Select USMLE Category</option>
                          {step1Categories.map((category, idx) => (
                            <option key={idx} value={category}>
                              {category}
                            </option>
                          ))}
                        </CFormSelect>
                      ) : (
                        ''
                      )}
                      {getValues('usmleStep') == '2' ? (
                        <CFormSelect
                          label="USMLE Category"
                          aria-label="usmle category"
                          id="usmleCategory"
                          defaultValue={getValues('usmleCategory')}
                          // options={[
                          //   { label: 'Select USMLE Category', value: '' },
                          //   { label: 'Internal Medicine', value: 'Internal Medicine' },
                          //   { label: 'Surgery', value: 'Surgery' },
                          //   { label: 'Pediatrics', value: 'Pediatrics' },
                          //   {
                          //     label: 'Obstetrics and Gynecology',
                          //     value: 'Obstetrics and Gynecology',
                          //   },
                          //   { label: 'Psychiatry', value: 'Psychiatry' },
                          //   { label: 'Preventive Medicine', value: 'Preventive Medicine' },
                          //   { label: 'Family Medicine', value: 'Family Medicine' },
                          // ]}
                          // onChange={(e) => setUsmleCategory(e.target.value)}
                          {...register('usmleCategory', { required: true })}
                          feedback="Please select USMLE Category."
                          invalid={errors.usmleCategory ? true : false}
                        >
                          <option>Select USMLE Category</option>
                          {step2Categories.map((category, idx) => (
                            <option key={idx} value={category}>
                              {category}
                            </option>
                          ))}
                        </CFormSelect>
                      ) : (
                        ''
                      )}
                      {getValues('usmleStep') == '3' ? (
                        <CFormSelect
                          label="USMLE Category"
                          aria-label="usmle category"
                          id="usmleCategory"
                          defaultValue={getValues('usmleCategory')}
                          // options={[
                          //   { label: 'Select USMLE Category', value: '' },
                          //   { label: 'Internal Medicine', value: 'Internal Medicine' },
                          //   { label: 'Surgery', value: 'Surgery' },
                          //   { label: 'Pediatrics', value: 'Pediatrics' },
                          //   {
                          //     label: 'Obstetrics and Gynecology',
                          //     value: 'Obstetrics and Gynecology',
                          //   },
                          //   { label: 'Psychiatry', value: 'Psychiatry' },
                          //   { label: 'Preventive Medicine', value: 'Preventive Medicine' },
                          //   { label: 'Family Medicine', value: 'Family Medicine' },
                          // ]}
                          // onChange={(e) => setUsmleCategory(e.target.value)}
                          {...register('usmleCategory', { required: true })}
                          feedback="Please select USMLE Category."
                          invalid={errors.usmleCategory ? true : false}
                        >
                          <option>Select USMLE Category</option>
                          {step3Categories.map((category, idx) => (
                            <option key={idx} value={category}>
                              {category}
                            </option>
                          ))}
                        </CFormSelect>
                      ) : (
                        ''
                      )}
                    </CCol>
                  </CRow>
                ) : (
                  ''
                )}
                <CRow className="mb-3">
                  <CCol md={12}>
                    {/* <CFormInput
                      label="Question"
                      type="text"
                      id="ques"
                      placeholder="Enter Question"
                      // value={question}
                      // onChange={(e) => setQuestion(e.target.value)}
                      {...register('question', { required: true })}
                      feedback="Question is required"
                      invalid={errors.question ? true : false}
                    /> */}
                    <label className="form-label">Question</label>
                    {/* <ReactQuill
                      theme="snow"
                      name="question"
                      value={getValues('question')}
                      // value={...register('question', { required: true })}
                      // onChange={(e) => console.log('question', e.toString())}
                      placeholder="Enter question here"
                      formats={formats}
                      modules={modules}
                      onChange={(e) => setValue('question', e.toString())}
                    /> */}
                    <JoditEditor
                      value={getValues('question')}
                      config={config}
                      onChange={(e) => setValue('question', e.toString())}
                    />
                    {errors.question && (
                      <span className="text-red-500 text-sm">Question is required</span>
                    )}
                  </CCol>
                </CRow>
                <CRow className="mb-3">
                  <CCol md={12}>
                    {/* <CFormTextarea
                      label="Explaination"
                      type="text"
                      id="explain"
                      rows={4}
                      placeholder="Explain question"
                      {...register('explaination', { required: true })}
                      feedback="Explaination is required"
                      invalid={errors.explaination ? true : false}
                    /> */}
                    <label className="form-label">Explanation</label>
                    {/* <ReactQuill
                      theme="snow"
                      name="question"
                      value={getValues('explaination')}
                      placeholder="Enter question explanation here"
                      formats={expformats}
                      modules={expmodules}
                      onChange={(e) => setValue('explaination', e.toString())}
                    /> */}
                    <JoditEditor
                      ref={editor}
                      value={getValues('explaination')?.replace(/\(Choice/g, '<br/>• (Option')}
                      config={config}
                      tabIndex={1}
                      onChange={(e) => setValue('explaination', e.toString())}
                    />
                    {errors.explaination && (
                      <span className="text-red-500 text-sm">Explanation is required</span>
                    )}
                  </CCol>
                </CRow>
                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="options">Options</CFormLabel>
                    <CRow>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="First option"
                          type="text"
                          // onChange={(e) => setOp1(e.target.value)}
                          // value={op1}
                          {...register('op1', { required: true })}
                          feedback="Option 1 is required"
                          invalid={errors.op1 ? true : false}
                          className="mb-2"
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="First option explaination"
                          type="text"
                          // {...register('op1Explain', { required: true })}
                          // feedback="Explaination of Option 1 is required"
                          // invalid={errors.op1Explain ? true : false}
                          onChange={(e) => setOp1Exp(e.target.value)}
                          value={op1Exp}
                          disabled={questionId ? (op1Exp ? false : true) : false}
                          className="mb-2"
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Second option"
                          type="text"
                          {...register('op2', { required: true })}
                          feedback="Option 2 is required"
                          invalid={errors.op2 ? true : false}
                          className="mb-2"
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Second option explaination"
                          type="text"
                          // {...register('op2Explain', { required: true })}
                          // feedback="Explaination of Option 2 is required"
                          // invalid={errors.op2Explain ? true : false}
                          onChange={(e) => setOp2Exp(e.target.value)}
                          value={op2Exp}
                          disabled={questionId ? (op2Exp ? false : true) : false}
                          className="mb-2"
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Third option"
                          type="text"
                          {...register('op3', { required: true })}
                          feedback="Option 3 is required"
                          invalid={errors.op3 ? true : false}
                          className="mb-2"
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Third option explaination"
                          type="text"
                          // {...register('op3Explain', { required: true })}
                          // feedback="Explaination of Option 3 is required"
                          // invalid={errors.op3Explain ? true : false}
                          onChange={(e) => setOp3Exp(e.target.value)}
                          value={op3Exp}
                          disabled={questionId ? (op3Exp ? false : true) : false}
                          className="mb-2"
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Forth option"
                          type="text"
                          // onChange={(e) => setOp1(e.target.value)}
                          // value={op1}
                          {...register('op4', { required: true })}
                          feedback="Option 4 is required"
                          invalid={errors.op4 ? true : false}
                          className="mb-2"
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Forth option explaination"
                          type="text"
                          // {...register('op4Explain', { required: true })}
                          // feedback="Explaination of Option 4 is required"
                          // invalid={errors.op4Explain ? true : false}
                          onChange={(e) => setOp4Exp(e.target.value)}
                          value={op4Exp}
                          disabled={questionId ? (op4Exp ? false : true) : false}
                          className="mb-2"
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Fifth option"
                          type="text"
                          // onChange={(e) => setOp1(e.target.value)}
                          // value={op1}
                          {...register('op5', { required: true })}
                          feedback="Option 5 is required"
                          invalid={errors.op5 ? true : false}
                          className="mb-2"
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Fifth option explaination"
                          type="text"
                          // {...register('op5Explain', { required: true })}
                          // feedback="Explaination of Option 5 is required"
                          // invalid={errors.op5Explain ? true : false}
                          onChange={(e) => setOp5Exp(e.target.value)}
                          value={op5Exp}
                          disabled={questionId ? (op5Exp ? false : true) : false}
                          className="mb-2"
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Sixth option"
                          type="text"
                          onChange={(e) => setOp6(e.target.value)}
                          value={op6}
                          className="mb-2"
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          placeholder="Sixth option explaination"
                          type="text"
                          onChange={(e) => setOp6Exp(e.target.value)}
                          value={op6Exp}
                          disabled={questionId ? (op6Exp ? false : true) : false}
                          className="mb-2"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormSelect
                      label="Correct Option"
                      aria-label="correct option"
                      id="correct"
                      defaultValue={getValues('correct')}
                      options={
                        op6
                          ? [
                              { label: 'Select Correct Option', value: '' },
                              { label: getValues('op1'), value: getValues('op1') },
                              { label: getValues('op2'), value: getValues('op2') },
                              { label: getValues('op3'), value: getValues('op3') },
                              { label: getValues('op4'), value: getValues('op4') },
                              { label: getValues('op5'), value: getValues('op5') },
                              { label: op6, value: op6 },
                            ]
                          : [
                              { label: 'Select Correct Option', value: '' },
                              { label: getValues('op1'), value: getValues('op1') },
                              { label: getValues('op2'), value: getValues('op2') },
                              { label: getValues('op3'), value: getValues('op3') },
                              { label: getValues('op4'), value: getValues('op4') },
                              { label: getValues('op5'), value: getValues('op5') },
                            ]
                      }
                      {...register('correct', { required: true })}
                      feedback="Please select correct option"
                      invalid={errors.correct ? true : false}
                    />
                  </CCol>
                </CRow>
                {questionId && image ? (
                  <CRow className="mb-3">
                    <CCol md={6}>
                      <CFormInput
                        type="file"
                        id="formFile"
                        label="Change Image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                      <CButton
                        color="danger"
                        onClick={deleteImage}
                        className="mt-3"
                        disabled={imgLoader ? true : false}
                      >
                        {imgLoader ? <CSpinner color="light" size="sm" /> : 'Delete Image'}
                      </CButton>
                    </CCol>
                    <CCol md={6}>
                      <center>
                        <img
                          src={`${API_URL}uploads/images/${image}`}
                          alt="image"
                          className="w-52 h-36 rounded-lg"
                        />
                      </center>
                    </CCol>
                  </CRow>
                ) : (
                  <CRow className="mb-3">
                    <CCol md={12}>
                      <CFormInput
                        type="file"
                        id="formFile"
                        label="Image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </CCol>
                  </CRow>
                )}
                {questionId && image2 ? (
                  <CRow className="mb-3">
                    <CCol md={6}>
                      <CFormInput
                        type="file"
                        id="formFile"
                        label="Change Explanation Image"
                        onChange={(e) => setImage2(e.target.files[0])}
                      />
                      <CButton
                        color="danger"
                        onClick={deleteImage2}
                        className="mt-3"
                        disabled={img2Loader ? true : false}
                      >
                        {img2Loader ? <CSpinner color="light" size="sm" /> : 'Delete Image'}
                      </CButton>
                    </CCol>
                    <CCol md={6}>
                      <center>
                        <img
                          src={`${API_URL}uploads/images/${image2}`}
                          alt="image"
                          className="w-52 h-36 rounded-lg"
                        />
                      </center>
                    </CCol>
                  </CRow>
                ) : (
                  <CRow className="mb-3">
                    <CCol md={12}>
                      <CFormInput
                        type="file"
                        id="formFile"
                        label="Explanation Image"
                        onChange={(e) => setImage2(e.target.files[0])}
                      />
                    </CCol>
                  </CRow>
                )}
                {questionId && video ? (
                  <CRow className="mb-3">
                    <CCol md={6}>
                      <CFormInput
                        type="file"
                        id="formFile"
                        label="Change Video"
                        onChange={(e) => handleVideoChange(e.target.files[0])}
                      />
                      <CButton
                        color="danger"
                        onClick={deleteVideo}
                        className="mt-3"
                        disabled={videoLoader ? true : false}
                      >
                        {videoLoader ? <CSpinner color="light" size="sm" /> : 'Delete Video'}
                      </CButton>
                    </CCol>
                    <CCol md={6}>
                      <center>
                        <video controls>
                          {video && (
                            <source src={`${API_URL}uploads/videos/${video}`} type="video/mp4" />
                          )}
                        </video>
                      </center>
                    </CCol>
                  </CRow>
                ) : (
                  <CRow className="mb-3">
                    <CCol md={12}>
                      <CFormInput
                        type="file"
                        id="formFile"
                        label="Video"
                        onChange={(e) => handleVideoChange(e.target.files[0])}
                      />
                    </CCol>
                  </CRow>
                )}
                {/* <CRow className="mb-3">
                  <CCol md={12}>
                    <p className="form-label">Video</p>
                    <DropBox
                      file={prevVideo ? `${API_URL}uploads/videos/${video}` : video}
                      // file={questionId && videoSrc ? `${API_URL}uploads/videos/${video}` : video}
                      setFile={setVideo}
                      fileEnter={fileEnter}
                      setFileEnter={setFileEnter}
                      setVideoSrc={setVideoSrc}
                    />
                    <div className="flex justify-center items-center mt-3">
                      {video && (
                        <CButton
                          onClick={() => {
                            setVideo('')
                            setVideoSrc('')
                            setPrevVideo(false)
                          }}
                          color="danger"
                          // className="px-4 mt-10 uppercase py-2 tracking-widest outline-none bg-red-600 text-white rounded"
                        >
                          Reset
                        </CButton>
                      )}
                      {questionId && video && (
                        <CButton
                          color="danger"
                          onClick={deleteVideo}
                          className="ml-3"
                          disabled={videoLoader ? true : false}
                        >
                          {videoLoader ? <CSpinner color="light" size="sm" /> : 'Delete Video'}
                        </CButton>
                      )}
                    </div>
                  </CCol>
                </CRow> */}
                {/* <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormInput
                      type="file"
                      id="formFile"
                      label="Upload Video"
                      onChange={(e) => handleVideoChange(e.target.files[0])}
                    />
                  </CCol>
                </CRow>
                {videoSrc && (
                  <center>
                    <Player playsInline src={videoSrc} fluid={false} width={480} height={272} />
                  </center>
                )} */}
              </CForm>
              {error && <p className="mt-3 text-base text-red-700">{errorMsg}</p>}
            </CModalBody>
            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => {
                  setAddModal(false)
                  setQuestionId('')
                }}
              >
                Close
              </CButton>
              {questionId ? (
                <CButton color="primary" type="submit" disabled={loading ? true : false}>
                  {loading ? <CSpinner color="light" size="sm" /> : 'Save'}
                </CButton>
              ) : (
                <CButton color="primary" type="submit" disabled={loading ? true : false}>
                  {loading ? <CSpinner color="light" size="sm" /> : 'Add'}
                </CButton>
              )}
            </CModalFooter>
          </CForm>
        </CModal>
        {/* delete modal */}
        <CModal
          alignment="center"
          visible={deleteModal}
          onClose={() => setDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
          backdrop="static"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">Delete Question</CModalTitle>
          </CModalHeader>
          <CModalBody>
            Are you sure to delete this question?
            {error && <p className="mt-3 text-base text-red-700">{errorMsg}</p>}
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setDeleteModal(false)}>
              No
            </CButton>
            <CButton color="primary" onClick={deleteQuestion} disabled={loading ? true : false}>
              {loading ? <CSpinner color="light" size="sm" /> : 'Yes'}
            </CButton>
          </CModalFooter>
        </CModal>
        {/* bulk delete modal */}
        <CModal
          alignment="center"
          visible={bulkDeleteModal}
          onClose={() => setBulkDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
          backdrop="static"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">Delete Questions</CModalTitle>
          </CModalHeader>
          <CModalBody>
            Are you sure to delete all selected questions?
            {error && <p className="mt-3 text-base text-red-700">{errorMsg}</p>}
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setBulkDeleteModal(false)}>
              No
            </CButton>
            <CButton color="primary" onClick={bulkdeleteQuestion} disabled={loading ? true : false}>
              {loading ? <CSpinner color="light" size="sm" /> : 'Yes'}
            </CButton>
          </CModalFooter>
        </CModal>
        {/* quiz detail modal */}
        <CModal
          alignment="center"
          visible={detailModal}
          backdrop="static"
          onClose={() => {
            setDetailModal(false)
            reset({})
            setImage('')
            setOp6('')
            setOp6Exp('')
          }}
          aria-labelledby="VerticallyCenteredExample"
          size="lg"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">Question Details</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow className="mb-2">
              <CCol md={2}>
                <strong>Question</strong>
              </CCol>
              <CCol md={10}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getValues('question'),
                  }}
                >
                  {/* {getValues('question')} */}
                </span>
              </CCol>
            </CRow>
            <CRow className="mb-2">
              <CCol md={2}>
                <strong>Options</strong>
              </CCol>
              <CCol md={10}>
                <span>A. {getValues('op1')}</span>
                <br />
                <span>B. {getValues('op2')}</span>
                <br />
                <span>C. {getValues('op3')}</span>
                <br />
                <span>D. {getValues('op4')}</span>
                <br />
                <span>E. {getValues('op5')}</span>
                {op6 && (
                  <>
                    <br />
                    <span>F. {op6}</span>
                  </>
                )}
              </CCol>
            </CRow>
            <CRow className="mb-2">
              <CCol md={2}>
                <strong>Step</strong>
              </CCol>
              <CCol md={10}>
                <span>{getValues('usmleStep')}</span>
              </CCol>
            </CRow>
            <CRow className="mb-2">
              <CCol md={2}>
                <strong>Category</strong>
              </CCol>
              <CCol md={10}>
                <span>{getValues('usmleCategory')}</span>
              </CCol>
            </CRow>
            <CRow className="mb-2">
              <CCol md={2}>
                <strong>Explanation</strong>
              </CCol>
              <CCol md={10}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getValues('explaination')?.replace(/\(Choice/g, '<br/>• (Option'),
                  }}
                >
                  {/* {getValues('explaination')} */}
                </span>
              </CCol>
            </CRow>
            {op1Exp || op2Exp || op3Exp || op4Exp || op5Exp || op6Exp ? (
              <CRow className="mb-2">
                <CCol md={2}>
                  <strong>Explained Options</strong>
                </CCol>
                <CCol md={10}>
                  {op1Exp && (
                    <>
                      <span>A. {op1Exp}</span>
                      <br />
                    </>
                  )}
                  {op2Exp && (
                    <>
                      <span>B. {op2Exp}</span>
                      <br />
                    </>
                  )}
                  {op3Exp && (
                    <>
                      <span>C. {op3Exp}</span>
                      <br />
                    </>
                  )}
                  {op4Exp && (
                    <>
                      <span>D. {op4Exp}</span>
                      <br />
                    </>
                  )}
                  {op5Exp && (
                    <>
                      <span>E. {op5Exp}</span>
                      <br />
                    </>
                  )}
                  {op6Exp && (
                    <>
                      <span>F. {op6Exp}</span>
                      <br />
                    </>
                  )}
                </CCol>
              </CRow>
            ) : (
              ''
            )}
            <CRow className="mb-2">
              <CCol md={2}>
                <strong>Answer</strong>
              </CCol>
              <CCol md={10}>
                <span>{getValues('correct')}</span>
              </CCol>
            </CRow>
            {image && (
              <CRow>
                <CCol md={2}>
                  <strong>Image</strong>
                </CCol>
                <CCol md={10}>
                  <img
                    src={`${API_URL}uploads/images/${image}`}
                    alt="image"
                    className="w-52 h-36 rounded-full"
                  />
                </CCol>
              </CRow>
            )}
          </CModalBody>
          {/* <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setDetailModal(false)
                reset({})
                setImage('')
                setOp6('')
                setOp6Exp('')
              }}
            >
              Close
            </CButton>
          </CModalFooter> */}
        </CModal>
        {/* view quiz modal */}
        <CModal
          alignment="center"
          fullscreen
          visible={viewModal}
          backdrop="static"
          onClose={() => {
            setViewModal(false)
          }}
          aria-labelledby="VerticallyCenteredExample"
          size="lg"
        >
          <CModalHeader></CModalHeader>
          <CModalBody>
            <div className="p-10">
              {image || videoSrc ? (
                <CRow className="mb-5">
                  <CCol md={8}>
                    <p dangerouslySetInnerHTML={{ __html: getValues('question') }}></p>
                  </CCol>
                  <CCol md={4}>
                    {image && (
                      <img
                        // src={image}
                        src={`${API_URL}uploads/images/${image}`}
                        alt="question image"
                        className="mb-3"
                      />
                    )}
                    {videoSrc && (
                      <video controls>
                        {videoSrc && (
                          <source src={`${API_URL}uploads/videos/${videoSrc}`} type="video/mp4" />
                        )}
                      </video>
                    )}
                  </CCol>
                </CRow>
              ) : (
                <CRow className="mb-5">
                  <CCol md={12}>
                    <p dangerouslySetInnerHTML={{ __html: getValues('question') }}></p>
                  </CCol>
                </CRow>
              )}
              <div></div>
              <CForm>
                <div className="bg-gray-200 border-3 border-solid border-gray-400 text-black p-4 mb-3 min-w-64 w-fit">
                  <>
                    <div className="form-check">
                      <input
                        type="radio"
                        id={getValues('op1')}
                        name={getValues('correct')}
                        value={getValues('op1')}
                        className="form-check-input"
                      />
                      <label className={`form-check-label ml-2`}>A. {getValues('op1')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id={getValues('op2')}
                        name={getValues('correct')}
                        value={getValues('op2')}
                        className="form-check-input"
                      />
                      <label className={`form-check-label ml-2`}>B. {getValues('op2')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id={getValues('op3')}
                        name={getValues('correct')}
                        value={getValues('op3')}
                        className="form-check-input"
                      />
                      <label className={`form-check-label ml-2`}>C. {getValues('op3')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id={getValues('op4')}
                        name={getValues('correct')}
                        value={getValues('op4')}
                        className="form-check-input"
                      />
                      <label className={`form-check-label ml-2`}>D. {getValues('op4')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id={getValues('op5')}
                        name={getValues('correct')}
                        value={getValues('op5')}
                        className="form-check-input"
                      />
                      <label className={`form-check-label ml-2`}>E. {getValues('op5')}</label>
                    </div>
                    {op6 ? (
                      <div className="form-check">
                        <input
                          type="radio"
                          id={op6}
                          name={getValues('correct')}
                          value={op6}
                          className="form-check-input"
                        />
                        <label className={`form-check-label ml-2`}>F. {op6}</label>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                </div>
                <CButton color="primary" className="mx-auto px-5 rounded-full">
                  Next
                </CButton>
              </CForm>
            </div>
          </CModalBody>
        </CModal>
        {/* success alert */}
      </>
    </AdminLayout>
  )
}
export default ManageQuiz
